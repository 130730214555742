(function() {
  
  'use strict';
  /* global $, angular */

  angular.module('app.common')
    .directive('unwCommunitiesDropdown', function() {
      return {
        restrict: 'E',
        scope: {
          defaultPlaceholder: '@',
          checkboxPrefix: '@',
          selectedCommunities: '=ngModel',
          singleSelect: '='
        },
        templateUrl: '/common/unw-communities-dropdown',
        link: function($scope) {
          // Prevent dropdown from closing when click on checkbox
          $('.communities-drop-down .dropdown-accordion').on('click', 'li', function(event) {
            event.stopPropagation();
          });

          // Prevent page scroll while inside of accordion
          $('.communities-drop-down .dropdown-accordion').bind('mousewheel DOMMouseScroll', function(e) {
            var scrollTo = null;

            if (e.type == 'mousewheel') {
              scrollTo = (e.originalEvent.wheelDelta * -1);
            }
            else if (e.type == 'DOMMouseScroll') {
              scrollTo = 40 * e.originalEvent.detail;
            }

            if (scrollTo) {
              e.preventDefault();
              $(this).scrollTop(scrollTo + $(this).scrollTop());
            }
          });

        },
        controller: [ "$scope", "$timeout", "CommunitiesListDataService", function($scope, $timeout, CommunitiesListDataService) {
          $scope.labels = {
            community: $scope.defaultPlaceholder
          };

          $timeout(function() {
            $scope.selectedCommunities = _.map($scope.selectedCommunities, 'id') || [];
          }, 1500)

          // toggle community selections
          $scope.toggleCommunitySelection = function toggleCommunitySelection(id) {
            var idx = $scope.selectedCommunities.indexOf(id);
            // is currently selected
            if (idx > -1) {
              $scope.selectedCommunities.splice(idx, 1);
            }
            // is newly selected
            else {
              $scope.selectedCommunities.push(id);
            }

            // Update the dropdown label
            updateLabel(false);
          };

          // slect community for single-select mode
          $scope.selectCommunity = function selectCommunity(community) {
            $scope.selectedCommunities = [];
            $scope.selectedCommunities.push(community.id);
            updateLabel(community.name);
          };

          var updateLabel = function updateLabel(name) {
            var size = $scope.selectedCommunities.length;

            // single-select mode
            if (name) {
              $scope.labels.community = size == 0 ? $scope.defaultPlaceholder : name + " selected";
            } else {
            // multi-select mode
              switch (size) {
                case 0:
                  $scope.labels.community = $scope.defaultPlaceholder;
                  break;
                case 1:
                  $scope.labels.community = size + ' community selected';
                  break;
                default:
                  $scope.labels.community = size + ' communities selected';
                  break;
              }
            }
          };

          CommunitiesListDataService.getCommunities().then(function(result) {
            $scope.communities = result.communities;
          });

        }]
      };
    });

})();
