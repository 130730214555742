'use strict';
/* global $, angular */

angular.module('app.common')
    .directive('toggleGetUpdates', ['$timeout',
        function($timeout) {
            return {
                restrict: 'A',
                link: function(scope, elem, attrs) {

                  // click on the "Questions?" button in menu sidebar
                  elem.on('click', function(e) {
                    if ($(elem).hasClass('get-updates-link')) {
                      e.preventDefault();
                      $('.slideout-menu').toggleClass('open');
                      $('.navbar .menu-container .navbar-nav li.get-updates').addClass('hovered');
                    }
                  });

                  // hover the "Questions?" button in main nav
                  elem.on('mouseleave', function(e) {
                    if ($(elem).hasClass('get-updates')) {
                      $(elem).removeClass('hovered');
                    }
                  });

                }
            };
        }
    ])
