(function() {
  'use strict';

  angular.module('app.excellenceInEverythingWeDo')
    .controller('awardModalCtrl', ['$scope', '$uibModalInstance',
      function($scope, $uibModalInstance) {
        $scope.close = function() {
          $uibModalInstance.close();
        };
      }]);
})();