(function(){
  'use strict';

  angular.module('app.forms')
  .directive('unwFormWarrantyRequest', [function(){
    return {
      restrict: "E",
      scope: {
        formResult: "="
      },
      bindToController: true,
      controllerAs: 'vm',
      templateUrl: 'forms/unw-form-warranty-request',
      link: function() {},
      controller: ['FormService', 'DataService', 'DeviceService', 'TrackingService', '$rootScope',
        function(FormService, DataService, DeviceService, TrackingService, $rootScope) {

          /*====================================
          =            Declarations            =
          ====================================*/

          var self    = this,
          prefillMode = false;

          self.formData = FormService.prefillForm(prefillMode, 'warrantyRequest').formData;
          self.formResult = FormService.prefillForm(prefillMode, 'warrantyRequest').formResult;
          self.requests = 1;

          DataService('/warranty-request')
              .promise
              .then(function(result) {
                self.communities = result.underWarrantyCommunities;
              });

          if (typeof self.trackingName === 'undefined') {
            self.trackingName = 'warrantyRequestForm';
          }

          self.checkFormValidation = function(form) {
            self.triedSubmit = true;
            if (!form.$valid) {
              return;
            }

            processWarrantyRequestForm();
          };

          function processWarrantyRequestForm() {
            self.formResult.showForm = false;
            self.formResult.showLoader = true;

            FormService.submit('/forms/log-only', self.formData)
              .then(function(result) {
                self.formResult = result;
                if (result.status === 'success') {
                  console.log('tracking: ', self.trackingName);
                  TrackingService.logTrackingClick(self.trackingName, result);
                }
              });
          }

          // // Helper functions
          self.getRequests = function(num) {
            return new Array(num);
          };

          self.addAnotherRequest = function() {
            self.formData.warranty_issues.push(
                {
                  location: null,
                  description: null
                }
              );
            self.requests++;
            return false;
          };
        }]
    };
  }]);
})();