/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
  /**
   *
   * Directive to handle scrolling the page to the first invalid form element
   * upon an attempted submit.
   */

    .directive('unwAccessibleForm', ['$rootScope', '$window', 'DeviceService',
      function($rootScope, $window, DeviceService) {
        return {
          restrict: 'A',
          link: function(scope, elem) {

            // set up event handler on the form element
            elem.on('submit', function() {
              invokeSubmit();
            });

            $rootScope.$on('multiFormSubmit', function(e) {
              if (e.defaultPrevented) {
                return;
              }

              // An invalid form field was found
              if (invokeSubmit()) {
                e.defaultPrevented = true;
              }
            });

            var invokeSubmit = function() {
              // find the first invalid element
              var firstInvalid = elem[0].querySelector('.ng-invalid'),
                parentPos = findPos(elem.parent()[0]),
                windowOffset = DeviceService.check() === 'phone' ? 200 : 500;

              // if we find one, set focus
              if (firstInvalid) {
                firstInvalid.focus();
                var pos = findPos(firstInvalid);
                $window.scrollTo(0, pos[1] - windowOffset);

                return true;
              } else {
                $window.scrollTo(0, parentPos[1] - windowOffset);
              }

              return false;
            };

            function findPos(obj) {
              var curtop = 0;
              var curleft = 0;
              if (obj.offsetParent) {
                do {
                  curleft += obj.offsetLeft;
                  curtop += obj.offsetTop;
                } while (obj = obj.offsetParent);

                return [curleft, curtop];
              }
            }
          }
        };
      }]);
}());