/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentScheduleATour', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-schedule-a-tour',
        replace: true,
        scope: {
          formObj: "=",
          formScope: "="
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function(scope, element, attrs) {
          function s4() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
          }
          scope.id = s4();
        },
        controller: [function() {}]
      };

    }]);
}());