(function() {
  "use strict";

  angular
    .module('modules.device', [])
  ;

  angular
    .module('modules.device')
    .service('device', [
      function(){

          this.check = function(){
            var type = "desktop";
            /*detect ipad only*/
            if($(window).width() > 767 && $(window).width() < 1025){
              type =  "ipad";
            }
            /*detect phone only*/
            if($(window).width() < 768){
              type = "phone";
            }
            return type;
          };

          this.orientation = function(){
            var orientation = "landscape";
            if($(window).width() < $(window).height()){
              return "portrait";
            }
            return orientation;
          };

          this.touch = function(){
            var is_touch = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
            return is_touch;
          };

      }
    ])
  ;

})();