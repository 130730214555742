(function(){

  'use strict';

  angular.module('ui-rangeSlider').config(['$provide', function($provide) {
    $provide.decorator('rangeSliderDirective', function($delegate) {
      var dir = $delegate[0];

      dir.template = [
        '<div class="ngrs-range-slider">',
        '<div class="ngrs-runner">',
        '<div class="ngrs-handle ngrs-handle-min"><i></i></div>',
        '<div class="ngrs-handle ngrs-handle-max"><i></i></div>',
        '<div class="ngrs-join"></div>',
        '</div>',
        '<div class="ngrs-value-runner">',
        '<div class="ngrs-value ngrs-value-min" ng-show="showValues"><div>{{filteredModelMin | currencyK }}</div></div>',
        '<div class="ngrs-value ngrs-value-max" ng-show="showValues"><div>{{filteredModelMax | currencyK }}</div></div>',
        '</div>',
        '</div>'
      ].join('');

      return $delegate;
    })
  }]);

})();
