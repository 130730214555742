/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentHearSelect', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-hear-select',
        replace: true,
        scope: {
          defaultPlaceholder: '@',
          model: '=',
          formObj: '=',
          formScope: '=',
          selectName: '=?'
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [function() {
          var self = this;

          if (! self.defaultPlaceholder) {
            self.defaultPlaceholder = 'HOW DID YOU HEAR ABOUT US?*';
          }
          if (typeof self.selectName === 'undefined') {
            self.selectName = 'hear';
          }

          self.hears = [{
            "name": "Friend/Referral",
            "value": "Friend/Referral"
          }, {
            "name": "Drive-by/Signs",
            "value": "Drive-by/Signs"
          }, {
            "name": "Digital Display Ad",
            "value": "Digital Display Ad"
          }, {
            "name": "Email Marketing",
            "value": "Email Marketing"
          }, {
            "name": "Print Ad",
            "value": "Print Ad"
          }, {
            "name": "Internet Search",
            "value": "Internet Search"
          }, {
            "name": "Urban NW Event",
            "value": "Urban NW Event"
          }, {
            "name": "Social Media",
            "value": "Social Media"
          }, {
            "name": "Realtor",
            "value": "Realtor"
          }, {
            "name": "Radio",
            "value": "Radio"
          }, {
            "name": "Zillow",
            "value": "Zillow"
          }, {
            "name": "Trulia",
            "value": "Trulia"
          }, {
            "name": "RedFin",
            "value": "RedFin"
          }, {
            "name": "New Home Source",
            "value": "New Home Source"
          }, {
            "name": "Other",
            "value": "Other"
          }];
        }]
      };

    }]);
}());