/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentIndustrySelect', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-industry-select',
        replace: true,
        scope: {
          defaultPlaceholder: '@',
          model: '=',
          formObj: '=',
          formScope: '=',
          selectName: '=?'
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [function() {
          var self = this;

          if (! self.defaultPlaceholder) {
            self.defaultPlaceholder = 'ARE YOU IN THE REAL ESTATE OR BUILDING INDUSTRY?*';
          }
          if (typeof self.selectName === 'undefined') {
            self.selectName = 'industry';
          }

          self.industries = [{
            "name": "Real Estate",
            "value": "Real Estate"
          }, {
            "name": "Building",
            "value": "Building"
          }];
        }]
      };

    }]);
}());