/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeCtrl', ['$scope', 'CMSService', 'CommunitiesListDataService', 'laravelParameters', 'DataService', '$timeout',
      function($scope, CMSService, CommunitiesListDataService, laravelParameters, DataService, $timeout) {

        $scope.seoName = laravelParameters.segment(0);

        $scope.community = {};

        $scope.communityPromise = DataService('/community/' + $scope.seoName, 'community')
          .promise
          .then(function(community) {
            angular.copy(community, $scope.community);
            return $scope.community;
          })
        ;

        // gallery tree animation between slides
        $scope.nextSlide = function() {
          $timeout(function() {
            


            $('.transition').velocity({
              height: '165%'
            }, {
              duration: 700,
              complete: function() {
                $(this).velocity({
                  opacity: 0
                }, {
                  duration: 500,
                  complete: function() {
                    $('.item .carousel-caption').find('img').css('opacity', 0);
                    $(this).css({
                      bottom: -50,
                      height: '0%'
                    }).velocity({
                      bottom: 0,
                      opacity: 1
                    }, {
                      duration: 300,
                      complete: function() {
                        //fadeInCaption();
                      }
                    });
                  }
                });
              }
            });

          });
        };



      }
    ])
  ;
}());