/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.findYourHome')
    .controller('FindYourHomeCtrl', ['$scope', '$q', '$filter', '$timeout', 'uiGmapPromises', 'DataService', 'googleMapsStylesService', 'DeviceService',
      function($scope, $q, $filter, $timeout, uiGmapPromises, DataService, googleMapsStylesService, DeviceService) {

        // range slider setup
        $scope.priceRange = {
          min: 0,
          max: 500
        };

        $scope.communities = [];
        $scope.filteredCommunities = [];

        var specAggregates = {
          property_type: [],
          price: [],
          bed: [],
          bath: [],
          sqft: []
        };

        var offsetCenter = function(GoogleMaps, theMap, latlng, offsetx, offsety) {
          // return;
            // latlng is the apparent centre-point
            // offsetx is the distance you want that point to move to the right, in pixels
            // offsety is the distance you want that point to move upwards, in pixels
            // offset can be negative
            // offsetx and offsety are both optional

            var scale = Math.pow(2, theMap.getZoom());

            var worldCoordinateCenter = theMap.getProjection().fromLatLngToPoint(latlng);
            var pixelOffset = new GoogleMaps.Point((offsetx/scale) || 0,(offsety/scale) ||0);

            var worldCoordinateNewCenter = new GoogleMaps.Point(
                worldCoordinateCenter.x - pixelOffset.x,
                worldCoordinateCenter.y + pixelOffset.y
            );

            var newCenter = theMap.getProjection().fromPointToLatLng(worldCoordinateNewCenter);

            theMap.setCenter(newCenter);

            if (theMap.getZoom() > 15) {
              theMap.setZoom(15);
            } else {
              theMap.setZoom(theMap.getZoom() - 1);
            }

        };

        var loadCommunities = function() {
          return DataService('/api/community', 'communities')
            .promise
            .then(function(communities) {
              angular.forEach(communities, function(community) {
                var marker = {
                  longitude: community.longitude,
                  latitude: community.latitude,
                  idkey: community.id,
                  icon: '/images/site/fa-map-marker.png',
                  templateUrl: 'findYourHomeWindow.html',
                  community: community
                };
                $scope.communities.push(marker);
                // console.log(result.communities);

                if (specAggregates.property_type.indexOf(community.property_type) === -1) {
                  specAggregates.property_type.push(community.property_type);
                }
                specAggregates.price.push(community.price);
                specAggregates.bed.push(community.bed_low);
                specAggregates.bath.push(community.bath_low);
                specAggregates.sqft.push(community.sqft_low);
                specAggregates.sqft.push(community.sqft_high);
              });

              return $scope.communities;
            });
        };

        var defaultCenter = {
          latitude: 45.638023,
          longitude: -122.663495
        };


        $scope.map = {
          control: {},
          markers: [],
          zoom: 10,
          center: {
            latitude: 45.679395,
            longitude: -122.597837
          },
          options: {
            streetViewControl: false,
            draggable: true,
            scrollwheel: false,
            mapTypeControl: false,
            panControl: true,
            zoomControl: true,
            styles: googleMapsStylesService.unsaturatedBrown
          },
          mapEvents: {
            click: function(maps, eventName, args) {
              hideWindows();
            }
          },
          window: {
            control: {},
            marker: {},
            show: false,
            closeClick: function() {
              this.show = false;
            },
            options: {
              boxClass: 'popup-wrapper',
              maxWidth: 800,
              //width: 550
            }
          }
        };

        var fitBoundsWithCurrentMarkers = function(theMap, GoogleMaps) {
          GoogleMaps.event.trigger(theMap, 'resize');

          var newBounds = new GoogleMaps.LatLngBounds();

          angular.forEach($scope.map.markers, function(marker) {
            newBounds.extend(new GoogleMaps.LatLng(
              (0.005 + (1 * marker.latitude)),
              (0.005 + (1 * marker.longitude))
            ));
          });

          theMap.fitBounds(newBounds);
          offsetCenter(GoogleMaps, theMap, theMap.getCenter(), 0, 0);
        };

        var hideWindows = function() {
          // hide any windows
          $scope.map.window.show = false;
          $scope.map.window.marker = null;
          $scope.map.window.model = null;

          if (!$scope.$$phase) {
            $scope.$apply();
          }
        };

        var showWindow = function(GoogleMaps, marker, model) {
          $scope.map.window.options.pixelOffset = new GoogleMaps.Size(-285, -237);

          var markerCenter = {
            latitude: marker.getPosition().lat(),
            longitude: marker.getPosition().lng()
          };
          $scope.map.window.coords = markerCenter;

          hideWindows();

          $scope.map.window.marker = marker;
          $scope.map.window.model = model;
          $scope.map.window.show = true;

          uiGmapPromises.GoogleMapsIsAllReady()
            .then(function(theMap) {
            });
        };

        loadCommunities()
          .then(function(community_markers) {

            $scope.loading = false;
            uiGmapPromises.GoogleMapsApiReady()
              .then(function init(GoogleMaps) {

                var zoom = false;

                var zoomMax = 15;
                var zoomMin = 2;

                if(DeviceService.check() == 'phone'){
                  //zoomMax = 15;
                  // zoomMin = 9.5;
                }

                $scope.map.options = {
                    streetViewControl: false,
                    draggable: true,
                    scrollwheel: false,
                    mapTypeControl: false,
                    panControl: false,
                    panControlOptions: {
                      position: GoogleMaps.ControlPosition.RIGHT_CENTER
                    },
                    zoomControl: zoom,
                    zoomControlOptions: {
                      position: GoogleMaps.ControlPosition.RIGHT_CENTER
                    },
                    maxZoom: zoomMax,
                    minZoom: zoomMin,
                    styles: googleMapsStylesService.unsaturatedBrown,
                    closeBoxURL: 'https://www.google.com/intl/en_us/mapfiles/close.gif'
                  };

                $scope.map.mapEvents = {
                  click: function(maps, eventName, args) {
                    hideWindows();
                  }
                };

                $scope.map.markersEvents = {
                  mouseover: function(marker, eventName, model, args) {
                    showWindow(GoogleMaps, marker, model);
                  },
                  click: function(marker, eventName, model, args) {
                    showWindow(GoogleMaps, marker, model);
                  }
                };

                $scope.map.window = {
                  marker: {},
                  control: {},
                  model: null,
                  show: false,
                  closeClick: function() {
                    hideWindows();
                    $scope.map.window.show = false;
                    $scope.map.window.model = {};
                  },
                  options: {
                    boxClass: 'popup-wrapper',
                    closeBoxURL: 'https://www.google.com/intl/en_us/mapfiles/close.gif',
                    maxWidth: 800
                    //width: 555
                  }
                };

                $scope.map.markers = $scope.communities;

                    uiGmapPromises.GoogleMapsIsAllReady()
                      .then(function(theMap) {

                        $timeout(function() {

                          angular.element('.toggle-communities').trigger('click');

                          // re-size and re-center map
                          //$scope.map.window.options.pixelOffset = new GoogleMaps.Size(-6, -53);
                          fitBoundsWithCurrentMarkers(theMap, GoogleMaps);

                          // resize trigger
                          GoogleMaps.event.addDomListener(window, "resize", function() {
                            fitBoundsWithCurrentMarkers(theMap, GoogleMaps);
                          });
                        });

                      })
                    ;

              });
          });

      }]);
}());