/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';

  angular.module('app.common')
    .controller('GetUpdatesCtrl', ['$scope', function($scope) {
      $scope.toggleGetUpdatesForm = function($event) {
        $event.stopPropagation();
        $event.preventDefault();

        var bodyEl = $('body'),
          getUpdatesFormEl = $('.get-updates-form'),
          getUpdatesLinkEl = $('.get-updates'),
          containerEl = $('.get-updates-form-container');

        getUpdatesFormEl.toggleClass('active');
        getUpdatesLinkEl.toggleClass('active');

        bodyEl.on('click.hideGetUpdatesForm', function(event) {
          event.stopPropagation();
          event.preventDefault();

          // Prevent modal from closing if clicking inside the modal
          if (containerEl.find(event.target).length === 0) {
            // Hide get updates form
            getUpdatesFormEl.toggleClass('active');
            getUpdatesLinkEl.toggleClass('active');

            // Remove click event listener
            bodyEl.off('click.hideGetUpdatesForm');
          }
        });
      };
    }]);
}());
