/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('FeaturesCtrl', ['$scope', function($scope) { 

        $scope.oneAtATime = true;

        $scope.status = {
            isCustomHeaderOpen: false,
            isFirstOpen: true,
            isFirstDisabled: false
        };
      
    }]);
}());
