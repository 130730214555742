/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.ourTeam')
    .controller('OurTeamCtrl', ['$scope', 'CMSService', function($scope, CMSService) { 
    	
    	CMSService.loadContents('executive_team').then(function(result) {
	      $scope.executive_team = result.data.contents;
	    });
      
    }]);
}());