/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.getInTouch')
    .controller('GetInTouchCtrl', ['$scope',
      function($scope) {

    }]);
}());
