/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';  

  angular.module('app.portfolioCommunities')                   
    .controller('PortfolioCommunitiesCtrl', ['$scope', function($scope) { 
 	
 	$scope.portfolios = [

 		{
 			name: 'Jenelles Place',
 			id: 1,
 			copy: 'Jenelle’s Place is a centrally located in the Kevanna Park community and located just 5 minutes from the I-205 and SR 500 freeways. This community is located just south of Kevanna Park and only 3 miles from the Vancouver Mall. Jenelle’s Place also features a neighborhood park and the YMCA Community Center is nearby!  This community is truly a perfect match commuters and those who enjoy being minutes from great shopping, dining, and entertainment.  Custom colors, cabinets and finishes are available.',
 			images: [
 				{
 					image: '/images/site/portfolio-communities/img-1.jpg',
 				},
 				{
 					image: '/images/site/portfolio-communities/img-1.jpg',
 				},
 				{
 					image: '/images/site/portfolio-communities/img-1.jpg',
 				},
 				{
 					image: '/images/site/portfolio-communities/img-1.jpg',
 				},
 				{
 					image: '/images/site/portfolio-communities/img-1.jpg',
 				},
 			]

 		},

 		{
 			name: 'Mos Hollow',
 			id: 2,
 			copy: 'A quiet neighborhood nestled in the heart of South Salmon Creek. Mo Hollow encompasses 21 exclusive home sites settled on a low traffic, residential area.  These homes host large back yards for your garden and summertime barbeques.  The open floor plans make these homes very comfortable and inviting, including a large open loft area upstairs which can be used as a media area or extra living space.  Low VOC paints and finishes, as well as many energy efficient features make these homes a healthy and smart place to call home.',
 			images: [
 				{
 					image: '/images/site/portfolio-communities/img-2.jpg',
 				},
 				{
 					image: '/images/site/portfolio-communities/img-2.jpg',
 				},
 				{
 					image: '/images/site/portfolio-communities/img-2.jpg',
 				},
 				{
 					image: '/images/site/portfolio-communities/img-2.jpg',
 				},
 				{
 					image: '/images/site/portfolio-communities/img-2.jpg',
 				},
 			]

 		}

 	]
       
    }]);   
}());