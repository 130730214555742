/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';  

  angular.module('app.buildingProcess', ['angular-inview'])                   
    .controller('BuildingProcessCtrl', ['$scope', function($scope) { 

    	$scope.processes = [{
            text: 'The most important part of a successful custom build is our understanding the client’s vision.',
            number: '01', 
            color: 'blue',
            icon: '/images/site/building-process/step-1.png'
        }, {
            text: 'Understanding your financial goals as they relate to your new home is the next step on the path to your new home. Designing your dream home to fit your desired budget is easy once we explore your needs.',
            number: '02', 
            color: 'purple',
            icon: '/images/site/building-process/step-2.png'
        }, {
            text: 'Once Urban NW understands your goals and budget, our team of preferred lenders will help locate the financing best suited for you.',
            number: '03', 
            color: 'green',
            icon: '/images/site/building-process/step-3.png'
        }, {
            text: 'Looking for the perfect piece of property? Let your team of experts help in locating the best parcels available. Our team also assists in helping costing out lot improvements, wells, septic systems, and identifying easements and slopes that can affect the building cost.',
            number: '04', 
            color: 'brown',
            icon: '/images/site/building-process/step-4.png'
        }, {
            text: 'At this point in the process we will ask our client for preliminary commitment. This means both parties have been happy working together, have agreed on budget range, and will continue under certain terms.',
            number: '05', 
            color: 'blue',
            icon: '/images/site/building-process/step-5.png'
        }, {
            text: 'If you already have the floor plan you wish to build we’re able to bid out the plan to your specifications. We also provide custom plan sets, as well as a complete custom design process. From modern to traditional design, we can create the perfect plan for you.',
            number: '06', 
            color: 'purple',
            icon: '/images/site/building-process/step-6.png'
        }, {
            text: 'Now that we have a home site and the blueprints to build your new home, we can fine tune the budget to prepare the way for a formal contract. Part of firming up your budget is selecting the fit and finish of your home. Deciding carpet, paint and window coverings as well as predetermining your home’s energy and NGBS (National Green Building Standard) scores, are examples of the last few items needed to prepare you a formal estimate of your home’s cost to build.',
            number: '07', 
            color: 'green',
            icon: '/images/site/building-process/step-7.png'
        }, {
            text: 'Contracts are made up of 2 parts: the standard Washington State Purchase and Sale and accompanying addenda and the Urban NW Homes Construction Contract, which itemizes the features going into your new home. Earnest money is deposited with us at this time.',
            number: '08', 
            color: 'brown',
            icon: '/images/site/building-process/step-8.png'
        }, {
            text: 'Next you’ll be meeting with our Client Relationship Manger who will be coordinating appointments with our vendors to select you own custom finishes and fixtures. We’ll also be finalizing engineering and submit for your building permit. Typical processing time with Clark County is 6 to 7 months.',
            number: '09', 
            color: 'blue',
            icon: '/images/site/building-process/step-9.png'
        }, {
            text: 'Once we receive the permits back from the County we’re ready to get started – your crawl space dig will be scheduled immediately and you’ll be contacted directly by our job supervisor to have a pre-framing meeting. This meeting is used to go over the details of your construction contract and blueprints and to verify any last minute changes, electrical and plumbing layouts, and any other questions before the building starts. You’ll also have your Client Relationship Manager’s direct contact information for any other questions.',
            number: '10', 
            color: 'purple',
            icon: '/images/site/building-process/step-10.png'
        }, {
            text: 'The typical building process is 4-6 months. During this time questions regarding construction can be directed to your job supervisor and any pricing changes or additions need to be made through your Client Relationship Manager.',
            number: '11', 
            color: 'green',
            icon: '/images/site/building-process/step-11.png'
        }, {
            text: 'At the time construction is 100% complete, you’ll be contacted for the orientation meeting at your new home. This meeting covers all aspects of your home including the mechanical systems for the furnace, water heater, and fireplace, along with manufacturer warranty procedures. We can also note any items that need touch up or correction prior to closing.',
            number: '12', 
            color: 'brown',
            icon: '/images/site/building-process/step-12.png'
        }, {
            text: 'Leading up to the completion, your Client Relationship Manager will work with all parties to organize the final bank appraisal inspection and the signing and funding/recording timelines. Typically, signing will occur after the orientation meeting and once the home is fully funded, keys will be provided for move in.',
            number: '13', 
            color: 'blue',
            icon: '/images/site/building-process/step-13.png'
        }];
       
    }]);   
}());