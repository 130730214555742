'use strict';
/* global $, angular */

angular.module("app.common").directive('toggleClass', [function() {
	return function(scope, element, attrs) {
		if(attrs.hover === 'hover') {
			if(device.check() == "desktop") {
				element.bind('mouseenter',function(){
					$(attrs.targetObject).addClass(attrs.targetClass);
				})
				element.bind('mouseleave',function(){
					$(attrs.targetObject).removeClass(attrs.targetClass);
				}) 
			}
		} else {
			element.bind('click',function(){
				$(attrs.targetObject).toggleClass(attrs.targetClass);
			})
		}
	};
}]);