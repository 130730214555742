/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';

  angular.module('app.whyBuildGreen')
    .controller('WhyBuildGreenSubNavCtrl', ['$scope', '$location', '$rootScope', '$timeout', '$document',
      function($scope, $location, $rootScope, $timeout, $document) {

        $scope.toTheTop = function() {
          $document.scrollTopAnimated(0, 800).then(function() {
          });
        }

        //Watch affix event on main nav
        var $subNav = $('#sub-navigation-wrapper');
        var baseUrl = '';
        var offsetDistance = 100;
        var limit = 1;

        //scrollspy
        $(document).ready(function() {

          //trim the url based on these page classes
          var urlParts = $location.path().split('/');
          urlParts = urlParts.filter(function(item) {
            return item != ''
          });


          var num = 0;
          angular.forEach(urlParts, function(urlPart) {
            if (urlPart && num < limit) {
              baseUrl = baseUrl + urlPart + '/';
              num++;
            }
          });

          //on page load, trigger scroll to content
          var triggerScroll = function() { 
            if (urlParts[num] != undefined) {
              if (urlParts[num].length) {
                setTimeout(function() {
                  $('a[href="#' + urlParts[num] + '"]').click();
                }, 500);
              }
            }
          }

          if ($rootScope.loading === true) {
            $rootScope.$watch('loading', function(newValue) {
              if (newValue === false) {
                triggerScroll();
              }
            });
          } else {
            triggerScroll();
          }

        })

        $('body').scrollspy({
          target: '#sub-navigation-wrapper',
          offset: offsetDistance
        });

        $subNav.on('click', function() {
          $scope.$apply(function() {
            var uri = $subNav.find('li.active a').attr('href');
            $location.path(baseUrl + uri.replace('#', ''), false);
            $location.replace();
          });
        })

      }
    ])
.value('duScrollDuration', 800);

}());