/*jslint white: true, nomen: true */
/*global angular, $, document, window */
(function() {
    'use strict';

    angular.module('app.ourProcess')
        .directive('changeBackground', [function() {
            return {
                restrict: 'A',
                link: function(scope, elem, attrs) {

                    $('.carousel').on('slid.bs.carousel', function(e) {
                        var background = $(elem).find('.item.active').attr('background');
                        console.log(background);
                        $('.our-process-header').css('background-image', 'url(' + background + ')');
                    });

                }
            };
        }]); 
}());
