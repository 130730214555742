/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeAvailableHomesCtrl', ['$scope', '$uibModal',
      function($scope, $uibModal) {

        $scope.sortKey = 'price';
        $scope.sortRev = false;

        $scope.sort = function(key) {
          if (key === $scope.sortKey) {
            $scope.sortRev = !$scope.sortRev;
          } else {
            $scope.sortRev = true;
          }

          $scope.sortKey = key;
        };

        $scope.doSort = function(item) {
          var orderMonths = [
            'Now',
            'August',
            'September',
            'October',
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July'
          ];

          if (($scope.sortKey === 'availability' || $scope.sortKey === false) && item.available_label) {
            var month = item.available_label;
            return orderMonths.indexOf(month);
          }

          return item[$scope.sortKey];
        };

        $scope.openHome = function(home) {
          window.location.href = '/available-homes/'+home.seo_name+'/'+home.id;
        };

        $scope.openHomeVideoModal = function(home) {
          $scope.selectedHome = home;
          $scope.homeVideo = $scope.selectedHome.video_url;
          $uibModal.open({
            templateUrl: 'homeVideoModal.html',
            controller: 'homeVideoModalCtrl',
            windowClass: 'video-modal',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

        $scope.openGetUpdatesModal = function() {
            var modalInstance = $uibModal.open({
              templateUrl: 'getNotifiedModal.html',
              controller: 'getNotifiedModalCtrl',
              windowClass: 'modal-auto-size',
              animation: true,
              size: 'md',
              scope: $scope
            });
        };

    }])
    .controller('homeVideoModalCtrl', ['$scope', '$uibModalInstance',
      function($scope, $uibModalInstance) {
        $scope.close = function() {
          $uibModalInstance.close();
        };
      }]);
}());
