/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.realtorDashboard')
    .controller('RealtorExtranetClientListCtrl', ['$scope', '$http', '$window', '$filter', 'CommunitiesListDataService', '$uibModal', function($scope, $http, $window, $filter, CommunitiesListDataService, $uibModal) { 
    	
    	CommunitiesListDataService.getCommunities().then(function(result) {
	      $scope.communities = result.communities; 
	      console.log(result);
	    });

      $http({
        method: 'GET',
        url: '/realtor-extranet/client-list',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        } // set the headers so angular passing info as form data (not request payload)
      })
      .success(function(data) {
        if (data.status == 'success') {
          $scope.realtor = angular.copy(data.realtor);
          $scope.realtor.created_at = $filter('date')(Date.parse($scope.realtor.created_at), 'MMMM d, yyyy');

          angular.forEach($scope.realtor.clients, function(client, key) {
            client.created_at = $filter('date')(Date.parse(client.created_at), 'MM/dd/yyyy');
            client.communities = _.map(client.communities, 'name').join(', ');
          });
        } else {
        }
      });

      var modalInstance;

      $scope.openTermsAndConditionsModal = function() {
        modalInstance = $uibModal.open({
          templateUrl: 'realtorExtranetTermsAndConditionsModal.html',
          controller: 'realtorExtranetTermsAndConditionsModalCtrl',
          windowClass: 'modal-auto-size modal-realtor-extranet-terms-and-conditions',
          animation: true,
          size: 'md',
          scope: $scope
        });
      };

    }]);
}());