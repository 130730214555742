/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular
    .module('app.residenceDetail')
    .controller('ResidenceDetailCtrl', ['$scope', '$timeout', 'CommunitiesListDataService', 'CMSService', 'DataService', 'laravelParameters', '$uibModal', 'DeviceService',
      function($scope, $timeout, CommunitiesListDataService, CMSService, DataService, laravelParameters, $uibModal, DeviceService) {

        var init = function() {

          DataService('/' + laravelParameters.segment(0)[0])
            .promise
            .then(function(result) {

              $scope.residence = result.residence;
              $scope.otherPlans = result.other_plans;

            })
          ;

        };

        $scope.$watch(['residence', 'otherPlans'], init);



        $('#sub-nav-revision').affix({
          offset: {
            top: function () {
              return (this.top = $('header.menu').outerHeight(true) + $('.sub-header-revision').outerHeight(true) - 111)
            }
          }
        });
        /*
        .on('affix.bs.affix', function() {
          $('header.menu.fixed').addClass('push-it-up');
        }).on('affix-top.bs.affix', function() {
          $('header.menu.fixed').removeClass('push-it-up');
        });
        */
        var controller = new ScrollMagic.Controller();

        var scene = new ScrollMagic.Scene({
          triggerElement: '#sub-nav-revision',
          triggerHook: 'onEnter onLeave',
          offset: 345
        })
        .addTo(controller)
        .on('enter', function (e) {
          $('header.menu.fixed').addClass('push-it-up');
          console.log('trap');
        })
        .on('leave', function(e) {
          $('header.menu.fixed').removeClass('push-it-up');
        });

        $('#sub-navigation-wrapper').on('click', 'a', function(e) {
          // 141 = height of main header
          // 64 = height of sub nav
          var target = $(this).attr('href').substr(1),
              target_offset_top = $('#'+target).offset().top + 3;

          $('html, body').stop(true).animate({
            scrollTop: target_offset_top
          }, 800, 'easeOutQuart');
        });



        $scope.selectElevation = function(gallery, index) {
          $scope.selectImageAtIndex(index);
          $scope.elevationModal(gallery);
        }

        $scope.elevationModal = function(gallery) {
          $scope.selectedGallery = $scope.gallery;
          $uibModal.open({
            templateUrl: 'elevationModal.html',
            controller: 'galleryModalCtrl',
            windowClass: 'modal-auto-size gallery',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };



        var numOfTiles = (DeviceService.check() == 'phone') ? 12 : 18;
        $scope.galleryLimitTo = numOfTiles;
        //console.log($scope.floorplan.assets.length);
        $scope.loadMoreImages = function() {
          $scope.galleryLimitTo += numOfTiles;
        };



        $scope.selectImageAtIndex = function($index) {
          $scope.selectedIndex = $index;
        };

        $scope.selectImage = function(gallery, index) {
          $scope.selectImageAtIndex(index);
          $scope.galleryModal(gallery);
        }

        $scope.galleryModal = function(gallery) {
          $scope.selectedGallery = $scope.gallery;
          $uibModal.open({
            templateUrl: 'galleryModal.html',
            controller: 'galleryModalCtrl',
            windowClass: 'modal-auto-size gallery',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

        $scope.slickConfig = {
          enabled: false,
          dots: false,
          autoplay: false,
          fade: true,
          // speed: 1800,
          initialSlide: 0,
          infinite: true,
          autoplaySpeed: 10000,
          easing: "easeInElastic",
          useCSS: true,
          method: {},
          prevArrow: '<a class="left" href="#"><img src="/images/site/arrow-left.png" class="icon-prev"><div class="hover-ellipse"></div></a>',
          nextArrow : '<a class="right" href="#"><img src="/images/site/arrow-right.png" class="icon-next"><div class="hover-ellipse"></div></a>',
          event: {
            init: function(event, slick) {
              slick.slickGoTo(0); // slide to correct index when init
            },
            beforeChange: function(event, slick, currentSlide, nextSlide) {
              // return slideAnimation();
            }
          }
        };



        $scope.$watch('residence.elevationGallery', function(is) {
          if (is === undefined)
            return;

          $timeout(function() {
            $scope.slickConfig.enabled = true;
          }, 1000);

        });

        $scope.$watch('residence.photoGallery', function(is) {
          if (is === undefined)
            return;

          $timeout(function() {
            $scope.slickConfig.enabled = true;
          }, 1000);

        });



        $timeout(function() {
          $scope.slickConfig.enabled = true;
        }, 2000);

        console.log($scope.residence);

        $scope.openResidenceDetailModal = function(residence) {
          $scope.selectedResidence = residence;
          $uibModal.open({
            templateUrl: 'residenceDetailModal.html',
            controller: 'residenceDetailModalCtrl',
            windowClass: 'modal-auto-size',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

      }
    ]);
}());