/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.ourFloorplans')
    .controller('OurFloorplansCtrl', ['$scope', 'CMSService', 'DataService', 'FilterService', '$uibModal',
      function($scope, CMSService, DataService, FilterService, $uibModal) {

        var init = function(is) {
          if (is !== undefined) {
            return;
          }
          
          DataService('our-floorplans', 'plans').promise
            .then(function(plans) {
              $scope.floorplans = plans;

              // generate dynamic baths for filter feature
              plans.map(function (p) {
                if (p.bath_low != 0) bath_values.push(p.bath_low);
                if (p.bath_high != 0) bath_values.push(p.bath_high);
              });

              _.uniq(bath_values).sort().map(function (v) {
                $scope.processedBaths.push({
                  name: parseFloat(v),
                  value: v
                });
              });
            });
        };

        $scope.$watch('floorplans', init);

        $scope.processedBaths = [];
        var bath_values = [];

        // range slider setup
        $scope.priceRange = {
          min: 0,
          max: 500
        }

        $scope.floorplanFilter = function(plan) {

          if (plan.collection === undefined || !plan.collection) {
            return false;
          }

          if (plan.collection.name == 'Custom')
            return false;

          if (FilterService.collection)
            if (plan.collection.name != FilterService.collection)
              return false;

          if (FilterService.stories)
            if (plan.stories != FilterService.stories)
              return false;

          if (FilterService.sqft_low && plan.sqft_low < FilterService.sqft_low)
            return false;

          if (FilterService.sqft_high) {
            var high = plan.sqft_high || plan.sqft_low;

            if (high > FilterService.sqft_high)
              return false;
          }

          if (FilterService.beds) {
            var low = plan.bed_low;
            var high = plan.bed_high || low;

            if (!(low <= FilterService.beds && FilterService.beds <= high))
              return false;
          }

          if (FilterService.baths) {
            var low = plan.bath_low;
            var high = (plan.bath_high > low) ? plan.bath_high : low;

            if (!(low <= FilterService.baths && FilterService.baths <= high))
              return false;
          }

          if (FilterService.is_first_floor_master)
            if (plan.is_first_floor_master != FilterService.is_first_floor_master)
              return false;

          if (FilterService.priceMin) {
            if (plan.price < FilterService.priceMin) {
              return false;
            }
          }

          if (FilterService.priceMax) {
            var high = plan.price_high || plan.price;

            if (high > FilterService.priceMax) {
              return false;
            }
          }

          return plan;

        };

        $scope.openFloorplan = function(floorplan) {
          window.location.href = floorplan.seo_name;
        };

        $scope.slidePrev = function(floorplan) {
          $('#carousel-floorplan-'+floorplan.id).carousel('prev');
        };

        $scope.slideNext = function(floorplan) {
          $('#carousel-floorplan-'+floorplan.id).carousel('next');
        };

        $scope.openFloorplanElevationsModal = function(floorplan) {
          $scope.selectedFloorplan = floorplan;
          $uibModal.open({
            templateUrl: 'floorplanElevationsModal.html',
            controller: 'floorplanDetailModalCtrl',
            windowClass: 'modal-auto-size floorplan-elevations-modal',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

        $scope.openFloorplanDetailModal = function(floorplan) {
          $scope.selectedFloorplan = floorplan;
          $uibModal.open({
            templateUrl: 'floorplanDetailModal.html',
            controller: 'floorplanDetailModalCtrl',
            windowClass: 'modal-auto-size floorplan-detail-modal',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

      }
    ])
  ;

}());
