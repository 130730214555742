/*jslint white: true, nomen: true */
/*global angular, $, document, window */
(function() {
    'use strict';

    angular.module('app.ourProcess')
        .directive('updateNumber', [function() {
            return {
                restrict: 'A',
                link: function(scope, elem, attrs) {

                    $('.carousel').on('slid.bs.carousel', function(e) {
                        var number = $(elem).find('.item.active').attr('number');
                        console.log(number);
                        $('#carousel-process .process-number span').css('opacity', '0').html(number).velocity({opacity:1});
                    });

                }
            };
        }]); 
}());
