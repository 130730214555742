(function(){

  'use strict';

  angular.module('app.common')
    .directive('ribbon', [ '$interpolate',
      function($interpolate) {
        return {
          transclude: true,
          template: '<div class="ribbon-wrapper-green">' +
                    '  <div class="ribbon-green <% floorplan.collection.name | lowercase %>">' +
                    '    <ng-transclude></ng-transclude>' +
                    '  </div>' +
                    '</div>',
          link: function(scope, elem, attrs, ctrl, transclude) {
            transclude(scope, function(clone) {

              var interpolated = $interpolate(clone.text(), true);
              
              scope.$watch(interpolated, function(value){
                if (value.length <= 0) {
                  elem.hide();
                }
              });

            });
          }
        };
      }
    ])
  ;


})();
