/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app')
    .controller('GalleryCtrl', ['$scope', function($scope) {

    	$scope.slides = [
          {
            title: '2nd level patio',
            type: 'exterior',
            image: '/images/site/gallery/gallery-1.jpg'
          },
          {
            title: 'House shot',
            type: 'exterior',
            image: '/images/site/gallery/gallery-2.jpg'
          },
          {
            title: '2nd House shot',
            type: 'exterior',
            image: '/images/site/gallery/gallery-3.jpg'
          },
          {
            title: 'Kitchen',
            type: 'interior',
            image: '/images/site/gallery/gallery-4.jpg'
          },
          {
            title: 'Surrounding area',
            type: 'exterior',
            image: '/images/site/gallery/gallery-5.jpg'
          },
          {
            title: 'Room 1',
            type: 'interior',
            image: '/images/site/gallery/gallery-6.jpg'
          }
        ];
      
    }]);
}());
