/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';

  angular.module('modules.googleMaps')
  .service('LocalAmenitiesService', ['$sce', function($sce) {
    var defaultLocalAmenities = [{
      name: 'Schools',
      icon: '/images/site/fa-map-marker.png',
      pins: [],
      types: ['school', 'library']
    }, {
      name: 'Dining',
      icon: '/images/site/fa-map-marker.png',
      pins: [],
      types: ['bakery', 'bar', 'cafe', 'food', 'meal_delivery', 'meal_takeaway', 'restaurant']
    }, {
      name: 'Shopping',
      icon: '/images/site/fa-map-marker.png',
      types: ['beauty_salon', 'bicycle_store', 'book_store', 'clothing_store', 'department_store', 'electronics_store', 'furniture_store', 'hair_care', 'hardware_store', 'home_goods_store', 'jewelry_store', 'pet_store', 'shoe_store', 'shopping_mall'],
      pins: []
    }, {
      name: 'Grocery',
      icon: '/images/site/fa-map-marker.png',
      types: ['grocery_or_supermarket'],
      pins: []
    }, {
      name: 'Recreation',
      icon: '/images/site/fa-map-marker.png',
      pins: [],
      types: ['aquarium', 'art_gallery', 'campground', 'museum', 'park', 'zoo']
    }, {
      name: 'Entertainment',
      icon: '/images/site/fa-map-marker.png',
      types: ['amusement_park', 'bowling_alley', 'casino', 'movie_rental', 'movie_theater', 'night_club', 'spa', 'stadium'],
      pins: []
    }, {
      name: 'Pharmacy',
      icon: '/images/site/fa-map-marker.png',
      pins: [],
      types: ['pharmacy']
    }, {
      name: 'Hospitals',
      icon: '/images/site/fa-map-marker.png',
      pins: [],
      types: ['hospital']
    }];

    return {
      data: defaultLocalAmenities
    };
  }]);
}());
