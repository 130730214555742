(function(){
  'use strict';

  angular.module('app.forms')
  .directive('unwFormTermsAndConditions', [function(){
    return {
      restrict: "E",
      scope: {
        formResult: "="
      },
      bindToController: true,
      controllerAs: 'vm',
      templateUrl: 'forms/unw-form-terms-and-conditions',
      link: function() {},
      controller: ['$rootScope', 'FormService', 'DeviceService',
        function($rootScope, FormService, DeviceService) {

          /*====================================
          =            Declarations            =
          ====================================*/

          var self    = this,
          prefillMode = false;

          self.formData = FormService.prefillForm(prefillMode, 'termsAndConditions').formData;
          self.formResult = FormService.prefillForm(prefillMode, 'termsAndConditions').formResult;

          self.checkFormValidation = function(form) {
            self.triedSubmit = true;
            if (!form.$valid) {
              return;
            }

            processTermsAndConditionsForm();
          };

          function processTermsAndConditionsForm() {
            self.formResult.showForm = false;
            self.formResult.showLoader = true;

            FormService.submit('/realtor-extranet/my-profile', self.formData)
              .then(function(result) {
                self.formResult = result;
                $rootScope.closeTermsAndConditionsFormModal();
              });
          }
        }]
    };
  }]);
})();