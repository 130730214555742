(function() {
  'use strict';
  /* global $, angular */
  // Tests for this service are not written yet
  // Handle form functions and submissions and return message and instructions
  // Service only displays "pretty" messages, not debug info from the API
  angular.module("app.forms")
    .service("TrackingService", ['$http',
      function($http) {
        var self = {};

        self.getTrackingInfo = function(formName) {
          var trackingInfo = {
            headerGetUpdatesForm: {
              pageHit: '/ajax-goals/headerGetUpdatesForm'
            },
            fullInterestListForm: {
              pageHit: '/ajax-goals/fullInterestListForm'
            },
            scheduleAnAppointmentForm: {
              pageHit: '/ajax-goals/scheduleAnAppointmentForm'
            },
            realtorSignUpForm: {
              pageHit: '/ajax-goals/realtorSignUpForm'
            },
            getInTouchForm: {
              pageHit: '/ajax-goals/getInTouchForm'
            },
            onYourLotInquiryForm: {
              pageHit: '/ajax-goals/onYourLotInquiryForm'
            },
            bookTheUrbanBruBusForm: {
              pageHit: '/ajax-goals/bookTheUrbanBruBusForm'
            },
            modalGetUpdatesForm: {
              pageHit: '/ajax-goals/modalGetUpdatesForm'
            },
            getUpdatesForm: {
              pageHit: '/ajax-goals/modalGetUpdatesForm'
            },
            availableAcreageForm: {
              pageHit: '/ajax-goals/availableAcreageForm'
            },
            warrantyRequestForm: {
              pageHit: '/ajax-goals/warrantyRequestForm'
            },
            netZeroForm: {
              pageHit: '/ajax-goals/netZeroForm'
            }
          };

          return trackingInfo[formName];
        };

        self.logTrackingClick = function(formName, result, confirmation) {
          self.loadPseudoConfirmation(confirmation);

          var trackingInfo = self.getTrackingInfo(formName);

          // Fire off a Google Analytics goal hit
          ga('create', 'UA-36968742-1');
          ga(function(tracker) {
            ga('send', 'pageview', trackingInfo.pageHit);
          });
        };

        self.loadPseudoConfirmation = function(confirmation) {
          // create a hidden iframe element and set its source
          var iframe = document.createElement('iframe');
    
          iframe.style.display = 'none';
          iframe.src = confirmation || '/forms/pseudo-confirmation';
          iframe.referrerPolicy = 'same-origin';
    
          document.body.appendChild(iframe);
        };

        return self;
      }]);
})();