/*jslint white: true, nomen: true */
/*global angular */
(function() {
    'use strict';

    angular.module('app.greenComponents')
        .controller('GreenComponentsCtrl', ['$scope', function($scope) {

            
            $('.carousel').on('slid.bs.carousel', function () {
                var carouselData = $(this).data('bs.carousel');
                var currentIndex = carouselData.getItemIndex(carouselData.$element.find('.item.active'));
                console.log(currentIndex);

                if(currentIndex == 0){
                    $('.carousel-control.right').show();
                    $('.carousel-control.left').hide();
                }else{
                    $('.carousel-control.right').hide();
                    $('.carousel-control.left').show();
                }

            });    

            $scope.pins = [{
                house_side: 'left',
                icon: '/images/site/green-components/icon-7.png',
                left: '70%',
                top: '67%',
                flyout: 'top',
                title: 'HOME AUTOMATION TECHNOLOGY',
                description: 'The Internet of Things is here! Enjoy the ease of controlling your new home from your cell phone – available in every Urban NW home.'
            }, {
                house_side: 'left',
                icon: '/images/site/green-components/icon-8.png',
                left: '50%',
                top: '62%',
                flyout: 'left',
                title: 'ENERGY CONSERVATION',
                description: 'Use up to 50% less energy than a code built home - verified with your individual HERS score.'
            }, {
                house_side: 'left',
                icon: '/images/site/green-components/icon-3.png',
                left: '55%',
                top: '30%',
                flyout: 'right',
                title: 'EARTH FRIENDLY BUILDING PRACTICES',
                description: 'We care about our impact - that’s why we recycle the maximum amount of materials possible on your new home.'
            }, {
                house_side: 'left',
                icon: '/images/site/green-components/icon-5.png',
                left: '33%',
                top: '21%',
                flyout: 'bottom',
                title: 'SOLAR PANEL PREWIRE',
                description: 'Ready to go to the next level? Your home can be too with an optional solar prewire package.'
            }, {
                house_side: 'left',
                icon: '/images/site/green-components/icon-10.png',
                left: '12%',
                top: '60%',
                flyout: 'right',
                title: 'INDOOR AIR QUALITY',
                description: 'Breathe easy knowing Urban NW uses low off gassing products, protecting your family’s health.'
            }, {
                house_side: 'right',
                icon: '/images/site/green-components/icon-4.png',
                left: '30%',
                top: '65%',
                flyout: 'left',
                title: 'HIGH PERFORMANCE APPLIANCES',
                description: 'Energy Star certified appliances are a standard in all of our homes.'
            }, {
                house_side: 'right',
                icon: '/images/site/green-components/icon-2.png',                
                left: '25%',
                top: '30%',
                flyout: 'right',
                title: 'ADVANCED THERMAL ENVELOPE',
                description: 'Cool summers and warm winters with our Tyvek home wrap, superior air sealing and BIB insulation.'
            }, {
                house_side: 'right',
                icon: '/images/site/green-components/icon-6.png',                
                left: '50%',
                top: '45%',
                flyout: 'top',
                title: '3RD PARTY GREEN CERTIFICATION',
                description: 'All of our homes are certified to the National Green Building Standard (NGBS) Silver level or higher.'
            }, {
                house_side: 'right',
                icon: '/images/site/green-components/icon-9.png',                
                left: '73%',
                top: '23%',
                flyout: 'left',
                title: 'HEATING SYSTEM IN CONDITIONED SPACE',
                description: 'Work smarter – not harder. Placing your furnace in conditioned space makes for the most efficient heating and cooling. Available on most plans.'
            }];

        }]);
}());
