(function() {
  'use strict';

  angular.module('app.customFloorplans')
    .controller('floorplanElevationsModalCtrl', ['$scope', '$uibModalInstance',
      function($scope, $uibModalInstance) {
      	console.log($scope.selectedFloorplan);
      	$scope.test = $scope.selectedFloorplan;
        $scope.close = function() {
          $uibModalInstance.close();
        };
      }]);
})();