/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.newsAndPress')
    .controller('NewsAndPressCtrl', ['$scope', 'CMSService', function($scope, CMSService) { 
    	
    	CMSService.loadContents('news').then(function(result) {
	      $scope.news = result.data.contents;
	    });
      
    }]);
}());