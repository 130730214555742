(function() {
  'use strict';
  /* global $, angular */

  angular
    .module('app.microsite')
    .directive('navItem', [ '$location',
      function($location) {
        return {
          restrict: 'A',
          scope: {
            'href': '@navHref',
            'click': '=navClick'
          },
          transclude: true,
          template: "<a ng-class=\"{ active: activeTab(href) }\" ng-href=\"<% href %>\" ng-transclude>",
          link: function(scope, elem, attr) { 

            scope.clickCallback = function(event) {
              

              if (scope.click) {
                event.preventDefault();
                console.log("test");
                scope.click();
              }
            };

            // scope.reveal = function(tab) {
            //   var result = micrositeSubNavReveal[tab];

            //   if (result) {
            //     //elem.removeClass('ng-hide');
            //   } else {
            //     //elem.addClass('ng-hide');
            //   }

            //   return result;
            // };

            // scope.scrollToSection = function(event) {
            //   event.preventDefault();
            //   var getTarget = $(attr.href),
            //       target = $("#" + getTarget);
            //       console.log(target)
            //   $('html, body').stop(true).animate({
            //     scrollTop: $(target).offset().top
            //   }, 800, 'easeOutQuart');
            // }

            scope.activeTab = function(tab) {
              var active = ('/' + tab === $location.path());

              // This is a hack so the parent <li> will get the active class.
              // I think we should apply the active class only to the link, but... -wo 
              if (active) {
                elem.addClass('active');
              } else {
                elem.removeClass('active');
              }

              return active;
            };

          }
        };
      }
    ])
  ;
})();