(function() {

  'use strict';

  angular.module('app')

    .controller('WhereWeBuildMenuCtrl', ['$scope', 'uiGmapPromises', 'DataService', '$timeout', 'googleMapsStylesService',
      function($scope, uiGmapPromises, DataService, $timeout, googleMapsStylesService) {

        $scope.communities = DataService('/api/community', 'communities').records;

      }
    ])
  ;
})();