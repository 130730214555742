/*jslint white: true, nomen: true */
/*global angular, $, document, window */
(function() {
  'use strict';

  angular.module('app.homeownerTestimonials')
    .directive('masonryGrid', ["$timeout", function($timeout) {  
      return {
        restrict: 'A',
        link: function(scope, elem, attrs) { 
        		
            if(scope.$last) { 

              $('.grid').masonry({ 
        				  // set itemSelector so .grid-sizer is not used in layout
        				  itemSelector: attrs.selector,
        				  // use element for option
        				  //columnWidth: attrs.columnWidth,
        				  percentPosition: true,
        			})
               
              $timeout(function() {
                $(".grid").masonry("layout");
              }, 200);

            }

        }
      };
    }]);
}());