/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app')
    .controller('HomePageCtrl', ['$scope', 'CMSService', 'CommunitiesListDataService', function($scope, CMSService, CommunitiesListDataService) {
      
       // Get all of the active communities
    CommunitiesListDataService.getCommunities().then(function(result) {
      $scope.communities = result.communities; 
      console.log(result);
    });






      $scope.whereWeBuildMapPins = [{
        top: '160px',
        left: '24%',
        popup: {
          img: '/images/site/homepage/map-pin-img-01.png',
          title: 'Jenelle\'s Place',
          homeType: 'Single Familly Homes',
          price: '$219,900',
          link: '#'
        }
      }, {
        top: '245px',
        left: '21%',
        popup: {
          img: '/images/site/homepage/map-pin-img-01.png',
          title: 'Jenelle\'s Place',
          homeType: 'Single Familly Homes',
          price: '$219,900',
          link: '#'
        }
      }, {
        top: '280px',
        left: '35%',
        popup: {
          img: '/images/site/homepage/map-pin-img-01.png',
          title: 'Jenelle\'s Place',
          homeType: 'Single Familly Homes',
          price: '$219,900',
          link: '#'
        }
      }, {
        top: '230px',
        left: '53%',
        popup: {
          img: '/images/site/homepage/map-pin-img-01.png',
          title: 'Jenelle\'s Place',
          homeType: 'Single Familly Homes',
          price: '$219,900',
          link: '#'
        }
      }, {
        top: '355px',
        left: '39%',
        popup: {
          img: '/images/site/homepage/map-pin-img-01.png',
          title: 'Jenelle\'s Place',
          homeType: 'Single Familly Homes',
          price: '$219,900',
          link: '#'
        }
      }, {
        top: '380px',
        left: '46%',
        popup: {
          img: '/images/site/homepage/map-pin-img-01.png',
          title: 'Jenelle\'s Place',
          homeType: 'Single Familly Homes',
          price: '$219,900',
          link: '#'
        }
      }, {
        top: '495px',
        left: '53%',
        popup: {
          img: '/images/site/homepage/map-pin-img-01.png',
          title: 'Jenelle\'s Place',
          homeType: 'Single Familly Homes',
          price: '$219,900',
          link: '#'
        }
      }];

      $scope.whereWeBuildList = [{
        title: 'Urban Oaks',
        price: '200s',
        link: '#'
      }, {
        title: 'Jenelle\'s Place',
        price: '200s',
        link: '#'
      }, {
        title: 'Mo Hollow',
        price: '200x',
        link: '#'
      }, {
        title: 'Parker\'s Abby',
        price: '200s',
        link: '#',
        isComming: true
      }, {
        title: 'Urban Red Barn',
        price: '200s',
        link: '#',
        isComming: true
      }, {
        title: 'Urban Oaks',
        price: '200s',
        link: '#'
      }, {
        title: 'Jenelle\'s Place',
        price: '200s',
        link: '#'
      }, {
        title: 'Mo Hollow',
        price: '200x',
        link: '#'
      }];
    }]);
}());
