/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular
    .module('app.urbanBruBus')
    .controller('UrbanBruBusCtrl', ['$scope', 'DataService', '$uibModal', '$timeout',
      function($scope, DataService, $uibModal, $timeout) { 

        DataService('/api/plan', 'plans')
          .promise
          .then(function(plans) {
            $scope.floorplans = plans;
          })
        ;
        
        $scope.galleries = [{
          id: 1,
          image: { hd: '/images/site/urban-bru-bus/ubb-01.jpg?v=2', large: '/images/site/urban-bru-bus/ubb-01.jpg?v=2' }
        }, {
          id: 2,
          image: { hd: '/images/site/urban-bru-bus/ubb-02.jpg', large: '/images/site/urban-bru-bus/ubb-02.jpg' }
        }, {
          id: 3,
          image: { hd: '/images/site/urban-bru-bus/ubb-03.jpg', large: '/images/site/urban-bru-bus/ubb-03.jpg' }
        }, {
          id: 4,
          image: { hd: '/images/site/urban-bru-bus/ubb-04.jpg?v=2', large: '/images/site/urban-bru-bus/ubb-04.jpg?v=2' }
        }, {
          id: 5,
          image: { hd: '/images/site/urban-bru-bus/ubb-05.jpg', large: '/images/site/urban-bru-bus/ubb-05.jpg' }
        }, {
          id: 6,
          image: { hd: '/images/site/urban-bru-bus/ubb-06.jpg?v=2', large: '/images/site/urban-bru-bus/ubb-06.jpg?v=2' }
        }, {
          id: 7,
          image: { hd: '/images/site/urban-bru-bus/ubb-07.jpg', large: '/images/site/urban-bru-bus/ubb-07.jpg' }
        }, {
          id: 8,
          image: { hd: '/images/site/urban-bru-bus/ubb-08.jpg', large: '/images/site/urban-bru-bus/ubb-08.jpg' }
        }, {
          id: 9,
          image: { hd: '/images/site/urban-bru-bus/ubb-09.jpg', large: '/images/site/urban-bru-bus/ubb-09.jpg' }
        }, {
          id: 10,
          image: { hd: '/images/site/urban-bru-bus/ubb-10.jpg?v=2', large: '/images/site/urban-bru-bus/ubb-10.jpg?v=2' }
        }, {
          id: 11,
          image: { hd: '/images/site/urban-bru-bus/ubb-11.jpg', large: '/images/site/urban-bru-bus/ubb-11.jpg' }
        }, {
          id: 12,
          image: { hd: '/images/site/urban-bru-bus/ubb-12.jpg?v=2', large: '/images/site/urban-bru-bus/ubb-12.jpg?v=2' }
        }, {
          id: 13,
          image: { hd: '/images/site/urban-bru-bus/ubb-13.jpg?v=2', large: '/images/site/urban-bru-bus/ubb-13.jpg?v=2' }
        }, {
          id: 14,
          image: { hd: '/images/site/urban-bru-bus/ubb-14.jpg?v=2', large: '/images/site/urban-bru-bus/ubb-14.jpg?v=2' }
        }, {
          id: 15,
          image: { hd: '/images/site/urban-bru-bus/ubb-15.jpg?v=2', large: '/images/site/urban-bru-bus/ubb-15.jpg?v=2' }
        }, {
          id: 16,
          image: { hd: '/images/site/urban-bru-bus/ubb-16.jpg?v=2', large: '/images/site/urban-bru-bus/ubb-16.jpg?v=2' }
        }, {
          id: 17,
          image: { hd: '/images/site/urban-bru-bus/ubb-17.jpg?v=2', large: '/images/site/urban-bru-bus/ubb-17.jpg?v=2' }
        }];

        $scope.selectImageAtIndex = function($index) {
          $scope.selectedIndex = $index;
        };

        $scope.selectImage = function(gallery, index) {
          $scope.selectImageAtIndex(index);
          $scope.galleryModal(gallery);
        }

        $scope.galleryModal = function(gallery) {
          $scope.selectedGallery = $scope.gallery;
          $uibModal.open({
            templateUrl: 'galleryModal.html',
            controller: 'galleryModalCtrl',
            windowClass: 'modal-auto-size gallery',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };
        
        $scope.slickConfig = {
          enabled: false,
          dots: false,
          autoplay: false,
          fade: true,
          // speed: 1800,
          initialSlide: 1,
          infinite: true,
          autoplaySpeed: 10000,
          easing: "easeInElastic",
          useCSS: true,
          method: {},
          prevArrow: '<a class="left" href="#"><img src="/images/site/arrow-left.png" class="icon-prev"><div class="hover-ellipse"></div></a>',
          nextArrow : '<a class="right" href="#"><img src="/images/site/arrow-right.png" class="icon-next"><div class="hover-ellipse"></div></a>',
          event: {
            init: function(event, slick) {
              slick.slickGoTo(0); // slide to correct index when init
            },
            beforeChange: function(event, slick, currentSlide, nextSlide) {
              // return slideAnimation();
            }
          }
        };
        
        $scope.$watch('galleries', function(is) {
          if (is === undefined)
            return;

          $timeout(function() {
            $scope.slickConfig.enabled = true;
          }, 1000);

        });


      }
    ])
  ;
}());