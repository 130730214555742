(function(){
  'use strict';

  angular.module('app.forms')
  .directive('unwFormForgotPassword', [function(){
    return {
      restrict: "E",
      scope: {
        formResult: "="
      },
      bindToController: true,
      controllerAs: 'vm',
      templateUrl: 'forms/unw-form-forgot-password',
      link: function() {},
      controller: ['FormService', 'DeviceService',
        function(FormService, DeviceService) {

          /*====================================
          =            Declarations            =
          ====================================*/

          var self    = this,
          prefillMode = false;

          self.formData = FormService.prefillForm(prefillMode, 'forgotPassword').formData;
          self.formResult = FormService.prefillForm(prefillMode, 'forgotPassword').formResult;

          self.checkFormValidation = function(form) {
            self.triedSubmit = true;
            if (!form.$valid) {
              return;
            }

            processGetInTouchForm();
          };

          function processGetInTouchForm() {
            self.formResult.showForm = false;
            self.formResult.showLoader = true;

            FormService.submit('/realtor-extranet/forgot-password', self.formData)
              .then(function(result) {
                self.formResult = result;
              });
          }
        }]
    };
  }]);
})();