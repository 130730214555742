'use strict';
/* global $, angular */

angular.module("app.microsite").directive('floorplanCarousel', [ function() {
  return function(scope, element, attrs) {
    // show preview of prev and nex carousel slide
    console.log("test from floorplan js");

    $('.carousel .item').each(function () {
        var next = $(this).next();
        if (!next.length) {
            next = $(this).siblings(':first');
        }
        next.children(':first-child').clone().appendTo($(this));
        if (next.next().length > 0) {
            next.next().children(':first-child').clone().appendTo($(this));
        } else {
            $(this).siblings(':first').children(':first-child').clone().appendTo($(this)); 
        }
    });
  };
}]); 
                