/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.realtorDashboard')
    .controller('RealtorExtranetMyProfileCtrl', ['$rootScope', '$scope', '$http', '$window', '$filter', 'CommunitiesListDataService', '$uibModal', function($rootScope, $scope, $http, $window, $filter, CommunitiesListDataService, $uibModal) {
    	
    	CommunitiesListDataService.getCommunities().then(function(result) {
	      $scope.communities = result.communities; 
	      console.log(result);
	    });

      $scope.formNewData = {
      };
      $scope.showLoader = false;
      $scope.showMessage = false;
      $scope.showForm = true;

      $http({
        method: 'GET',
        url: '/realtor-extranet/my-profile',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        } // set the headers so angular passing info as form data (not request payload)
      })
      .success(function(data) {
        if (data.status == 'success') {
          $scope.formData = data.realtor;
          
          $scope.realtor = angular.copy(data.realtor);
          $scope.realtor.created_at = $filter('date')(Date.parse($scope.realtor.created_at), 'MMMM d, yyyy');

          if ($scope.realtor.terms_and_conditions === 0) {
            $scope.openTermsAndConditionsFormModal()
          }
        } else {
        }
      });

      var modalInstance;

      $scope.openTermsAndConditionsFormModal = function() {
        modalInstance = $uibModal.open({
          templateUrl: 'realtorExtranetTermsAndConditionsFormModal.html',
          controller: 'realtorExtranetTermsAndConditionsFormModalCtrl',
          windowClass: 'modal-auto-size modal-realtor-extranet-terms-and-conditions',
          animation: true,
          backdrop: 'static',
          size: 'md',
          scope: $scope
        });
      };

      $rootScope.closeTermsAndConditionsFormModal = function() {
        modalInstance.close();
      };

      $scope.openTermsAndConditionsModal = function() {
        modalInstance = $uibModal.open({
          templateUrl: 'realtorExtranetTermsAndConditionsModal.html',
          controller: 'realtorExtranetTermsAndConditionsModalCtrl',
          windowClass: 'modal-auto-size modal-realtor-extranet-terms-and-conditions',
          animation: true,
          size: 'md',
          scope: $scope
        });
      };

      $scope.checkFormValidation = function(form) {
        $scope.showMessage = false;
        if ($scope.formNewData.password === undefined) {
          // do nothing
        } else if ($scope.formNewData.password) {
          $scope.formData.password = $scope.formNewData.password;
        }

        $scope.editProfileTriedSubmit = true;
        if (!form.$valid) {
          console.log('form is not valid.');
          return;
        }
        processEditProfileForm();
      };
    
      function processEditProfileForm() {
        $scope.showLoader = true;
        $scope.showMessage = false;
        $scope.showForm = false;
        $http({
          method: 'POST',
          url: '/realtor-extranet/my-profile',
          data: $.param($scope.formData), // pass in data as strings
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } // set the headers so angular passing info as form data (not request payload)
        })
        .success(function(data) {
          if (data.status == 'success') {
            $scope.status = data.status;
            $scope.message = data.message;
            $scope.showLoader = false;
            $scope.showMessage = true;
            $scope.showForm = false;
            $window.location.href = '/realtor-extranet/my-profile';
          } else {
            $scope.status = data.status;
            $scope.message = data.message;
            $scope.showLoader = false;
            $scope.showMessage = true;
            $scope.showForm = true;
          }
        });
      }
      
    }]);
}());