/*jslint white: true, nomen: true */
/*global angular, $, document, window */
(function() {
  'use strict';

  angular.module('app.homeownerTestimonials')
    .directive('toggleOpen', ["$rootScope", function($rootScope) {  
      return {
        link: function(scope, elem, attrs) { 
        		function listen(event) {
		        var bind = scope.$on('removeToggle', function(event) {
		          $(elem).closest("div.homeowner-testimonials").removeClass('opened');
		          bind();
		        });
		      }
		      
		      $(elem).bind('click', function() {
		        if ($(elem).closest("div.homeowner-testimonials").hasClass('opened')) {
		          $(elem).closest("div.homeowner-testimonials").removeClass('opened');
		        } else {
		          $rootScope.$broadcast('removeToggle');
		          $(elem).closest("div.homeowner-testimonials").addClass('opened');
		          listen();
		        }
		        $(".grid").masonry("layout");
		      });
            

        }
      };
    }]);
}());