angular
  .module('modules.priceStartingAt')

  .filter('priceStartingAt', ['$filter', function($filter) {
    'use strict';

    return function(param, prefix, suffix) {

      if (typeof prefix === 'undefined') {
        prefix = "From the {s}";
      }

      if (typeof suffix === 'undefined') {
        suffix = "s";
      }

      if (param === undefined || param === null || param === 0 || param === '' || param === "0") {
        return "Call for pricing";
      }

      param = Math.floor(param);

      var second_place = param.toString()[1];
      var price_label = "low";

      if (second_place >= 3) {
        price_label = "mid";
      }

      if (second_place >= 7) {
        price_label = "high";
      }

      var significant_front_digits = 1;
      var round_to_significant_digits = (1 * param.toString().substring(0, significant_front_digits));
      var rounded_price = round_to_significant_digits * Math.pow(10, (param.toString().length - 1));

      var currency = "$" + Math.round(rounded_price)
        //.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        .split('.')[0];

      var result = prefix + " " + currency + suffix;
      result = result.replace('{s}', price_label);

      return result;

    };
  }])
;