/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.collection')
    .controller('CollectionFloorplansCtrl', ['$scope', '$uibModal',
      function($scope, $uibModal) {

        $scope.collectionFilter = "";
        $scope.floorplans = [];
        $scope.floorplanRows = [];

        var init = function() {
          if ($scope.collectionPromise === undefined) {
            return;
          }

          $scope.collectionPromise
            .then(function(collection) {
              angular.copy(collection.plans, $scope.floorplans);
              angular.copy($scope.floorplanRows = chunk($scope.floorplans, 4));
            })
          ;
        };

        $scope.$watch('floorplans', init);

        $scope.$watch('collectionFilter', function(){

          if ($scope.floorplans === undefined) {
            return;
          }

          var flops = $scope.floorplans.filter(filterFloorplansByCollection);

          $scope.floorplanRows = chunk(flops, 4);
        });


        var filterFloorplansByCollection = function(flp) {
          if ($scope.collectionFilter === '') {
            return flp;
          }

          if (flp.plan !== undefined && flp.plan) {
            if (flp.plan.collection !== undefined && flp.plan.collection) {
              if (flp.plan.collection.name === $scope.collectionFilter) {
                return flp;
              }
            }
          }
        };

        var chunk = function(arr, len) {
          var chunks = [];
          var i = 0;
          var n = arr.length;

          while (i < n) {
            chunks.push(arr.slice(i, i += len));
          }

          return chunks;
        };

        $scope.openFloorplanElevationsModal = function(floorplan) {
          $scope.selectedFloorplan = floorplan;
          $uibModal.open({
            templateUrl: 'floorplanElevationsModal.html',
            controller: 'floorplanDetailModalCtrl',
            windowClass: 'modal-auto-size floorplan-elevations-modal',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

      }
    ])
  ;
}());