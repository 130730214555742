(function(){
  'use strict';

  angular.module('app.forms')
  .directive('unwFormModalGetUpdates', [function(){
    return {
      restrict: "E",
      scope: {
        formResult: "=",
        trackingName: "=?"
      },
      bindToController: true,
      controllerAs: 'vm',
      templateUrl: 'forms/unw-form-modal-get-updates',
      link: function() {},
      controller: ['FormService', 'CommunitiesListDataService', 'DeviceService', 'TrackingService', '$rootScope',
        function(FormService, CommunitiesListDataService, DeviceService, TrackingService, $rootScope) {

          /*====================================
          =            Declarations            =
          ====================================*/

          var self    = this,
          prefillMode = false;

          self.formData = FormService.prefillForm(prefillMode, 'modalGetUpdates').formData;
          self.formResult = FormService.prefillForm(prefillMode, 'modalGetUpdates').formResult;

          CommunitiesListDataService.getCommunities().then(function(result) {
            self.communities = result.communities;
          });

          self.formData.selected_community = null;
          if (typeof communityId !== 'undefined' && communityId) {
            self.formData.selected_community = communityId;
          }

          if (typeof self.trackingName === 'undefined') {
            self.trackingName = 'modalGetUpdatesForm';
          }

          self.checkFormValidation = function(form) {
            self.triedSubmit = true;
            if (!form.$valid) {
              return;
            }

            processGetInTouchForm();
          };

          function processGetInTouchForm() {
            self.formResult.showForm = false;
            self.formResult.showLoader = true;

            FormService.submit('/forms/quick-signup', self.formData)
              .then(function(result) {
                self.formResult = result;
                if (result.status === 'success') {
                  console.log('tracking: ', self.trackingName);
                  TrackingService.logTrackingClick(self.trackingName, result);
                }
              });
          }
        }]
    };
  }]);
})();