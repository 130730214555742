/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentTermsAndConditions', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-terms-and-conditions',
        replace: true,
        scope: {
          formObj: "=",
          formScope: "="
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [function() {
          var self = this;
          var popover_content;

          if (self.formScope.formData.type === 'realtorSignUp') {
            angular.element('.terms-and-conditions').popover({
              html: true,
              placement: 'top',
              trigger: 'focus',
              content: function() {
                if (! popover_content) {
                  popover_content = angular.element('.popover-terms-and-conditions').html();
                }
                return popover_content;
              }
            }).data('bs.popover').tip().addClass('popover-terms-and-conditions');
          }
        }]
      };

    }]);
}());