/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.netZeroHomes')
    .controller('NetZeroHomesCtrl', ['$scope', 'FormService', 'TrackingService', function($scope, FormService, TrackingService) {

      /*====================================
      =            Declarations            =
      ====================================*/

      var self = this,
        prefillMode = false;

      self.formData = FormService.prefillForm(prefillMode, 'netZero').formData;
      self.formResult = FormService.prefillForm(prefillMode, 'netZero').formResult;

      if (typeof self.trackingName === 'undefined') {
        self.trackingName = 'netZeroForm';
      }

      self.checkFormValidation = function(form) {
        self.triedSubmit = true;
        if (! form.$valid) {
          return;
        }

        processNetZeroForm();
      };

      function processNetZeroForm() {
        self.formResult.showForm = false;
        self.formResult.showLoader = true;

        // mapping form fields to sync with zapier notification
        self.formData.phone = self.formData.phone_primary;

        FormService.submit('/forms/net-zero', self.formData)
          .then(function(result) {
            self.formResult = result;
            if (result.status === 'success') {
              console.log('tracking: ', 'netZeroForm');
              TrackingService.logTrackingClick('netZeroForm', result);
            }
          });
      }

    }]);
}());