/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.homeGallery')
    .controller('HomeGalleryCtrl', ['$scope', '$rootScope', 'CommunitiesListDataService', 'DataService', '$timeout', '$uibModal', 
      function($scope, $rootScope, CommunitiesListDataService, DataService, $timeout, $uibModal) {  
    	
      var nextPage = function(page, collection) {

        if (page === undefined || page == 0 || !page) {
          return false;
        }

        return DataService('/api/asset?page=' + page + "&collection=" + collection)
          .promise
          .then(function(result){
            angular.forEach(result.assets, function(a){
              $scope.gallery.push(a);

            });

            $scope.renderGallery();
            return result.nextPage;
          })
          .then(function(page){
            return nextPage(page, collection);
          })
        ;
      };

      $scope.gallery = [];
      $scope.selectedTag = false;
      $scope.selectedCollection = false;

      $scope.switchGallery = function(){
        $scope.selectedTag = false;
        return $rootScope.renderGallery();
      }

      $scope.$watch('selectedTag', function(){
        $rootScope.renderGallery();
      });


      nextPage(1, 'Community')
      .then(function(){
        nextPage(1, 'Custom');
      })


      var matchTag = function(tag, tags) {
        // No selection means everything okay
        if (!tag) {
          return true;
        }

        if (tags !== undefined) {
          for (var i = tags.length - 1; i >= 0; i--) {
            if (tags[i].name === tag) {
              return true;
            }
          }
        }

        return false;
      };

      var matchCollection = function(collection, collections) {
        // No selection means everything okay
        if (!collection) {
          return true;
        }

        if (collections !== undefined) {
          for (var i = collections.length - 1; i >= 0; i--) {
            if (collections[i].name === collection) {
              return true;
            }
          }
        }

        return false;
      };

      $scope.galleryFilter = function(image) {
        return matchTag($scope.selectedTag, image.tags);
      };

      $scope.communityFilter = function(image) {
        return matchCollection("Community", image.collections);
      };

      $scope.customFilter = function(image) {
        return matchCollection("Custom", image.collections);
      };

	    // remove when able to filter by collections
	    $scope.quantity = 3600;
      

        $scope.selectImageAtIndex = function($index) {
          $scope.selectedIndex = $index;
        };

        $scope.swipeLeft = function() {
          console.log("swiped next");
          $('.carousel').carousel('next');
        };

        $scope.swipeRight = function() {
          console.log("swiped prev");
          $('.carousel').carousel('prev');
        };

        // Community modal

        $scope.selectImageCommunity = function(gallery, index) {
          $scope.selectImageAtIndex(index);
          $scope.communityCollectionModal(gallery);
        }

        $scope.communityCollectionModal = function(gallery) {

          $scope.selectedGallery = $scope.gallery;
          $uibModal.open({
            templateUrl: 'communityCollectionModal.html',
            controller: 'galleryModalCtrl',
            windowClass: 'modal-auto-size gallery',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

        // Custom modal

        $scope.selectImageCustom = function(gallery, index) {
          $scope.selectImageAtIndex(index);
          $scope.customCollectionModal(gallery);
        }

        $scope.customCollectionModal = function(gallery) {
         
          $scope.selectedGallery = $scope.gallery;
          $uibModal.open({
            templateUrl: 'customCollectionModal.html',
            controller: 'galleryModalCtrl',
            windowClass: 'modal-auto-size gallery',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

    }]);
}());