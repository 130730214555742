/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app')
    .service('FilterService', [
      function() {

        return {
          priceMin: 0,
          priceMax: 550000
        };

      }
    ])

    .controller('FilterCtrl', [ '$scope', 'FilterService',
      function($scope, FilterService) {

        $scope.addFilter = function (key, value) {
          FilterService[key] = value;
        };

        $scope.clearFilter = function(key) {
          FilterService[key] = undefined;
        };

        $scope.$watch('is_first_floor_master', function(is) {
          FilterService.is_first_floor_master = is;
        });

        $scope.priceMin = 0;
        $scope.priceMax = 550000;

        $scope.addFilter('priceMin', $scope.priceMin);
        $scope.addFilter('priceMax', $scope.priceMax);


        $scope.$watch('priceMin', function(is) {
          FilterService.priceMin = is;
        });
      
        $scope.$watch('priceMax', function(is) {
          FilterService.priceMax = is;
        });
      }
    ])
  ;
}());
