/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';  

  angular.module('app.ourProcess')                   
    .controller('OurProcessCtrl', ['$scope', function($scope) { 

        $scope.processes = [{
            title: 'INITIAL LOT HOLD AND SELECTION PERIOD', 
            text: "Once you've identified your individual home site we'll secure it for you up to 7 days while going through the plan and options on your home.",
            number: '01', 
            active: 'active',
            image: '/images/site/our-process/header-bkg-1.jpg'
        }, {
            title: 'CONTRACTS',
            text: "Contracts are made up of 2 parts, the standard Washington State Purchase and Sale Agreement and accompanying addenda, along with the Urban NW Homes Construction Contract, which itemizes the features going into your new home. Earnest money is deposited with Urban NW Homes at this time, and we'll also need your lender approval letter.",
            number: '02', 
            active: '',
            image: '/images/site/our-process/header-bkg-2.jpg'
        }, {
            title: 'SELECTION PROCESS',
            text: "Customizing your new home is one of the benefits of building with Urban NW Homes. While we're going through the engineering and permit process, you'll be meeting with our vendors at their showrooms to choose your cabinets, hard surfaces, floor coverings and low voltage options. Our Client Relationship Manager will assist in setting these appointments up, and will be available to assist with questions every step of the way.",
            number: '03', 
            active: '',
            image: '/images/site/our-process/header-bkg-3.jpg'
        }, {
            title: "ENGINEERING AND PERMITTING ",
            text: "Once contracts are signed we move into the engineering and permitting phase. This period of time can vary depending on plan changes, customization, and the turnaround time with the local building department. The timeline for this process can range from 4-8 weeks.",
            number: '04', 
            active: '',
            image: '/images/site/our-process/header-bkg-4.jpg'
        }, {
            title: "PRE -CONSTRUCTION MEETING",
            text: "Once we receive the permits back from the building department you'll be contacted directly by our Construction Manager to schedule your pre -construction meeting. This meeting is used to go over the details of your construction contract and blueprints, and to verify any last minute changes, electrical and plumbing layouts, and any other questions before the building starts. You'll also have your Construction Manager’s direct contact info for any questions.",
            number: '05', 
            active: '',
            image: '/images/site/our-process/header-bkg-5.jpg'
        }, {
            title: "COURSE OF CONSTRUCTION",
            text: "The typical building process is 4-5 months. During this time questions regarding construction can be directed to your Construction Manager, and any pricing changes or additions need to be made through the Client Relationship Manager.",
            number: '06', 
            active: '',
            image: '/images/site/our-process/header-bkg-6.jpg'
        }, {
            title: "ORIENTATION MEETING",
            text: "At the time construction is 100% complete, you'll be contacted for the orientation meeting at your new home. This meeting covers all aspects of your home, including the mechanical systems for the furnace, water heater, and fireplace, along with manufacturer warranty procedures. We can also note any items that need touch up or correction prior to closing.",
            number: '07', 
            active: '',
            image: '/images/site/our-process/header-bkg-7.jpg'
        }, {
            title: "CLOSING",
            text: "Leading up to completion, our Client Relationship Manager will be working with all parties to line up the final bank appraisal inspection, and the signing and funding / recording timelines. Typically signing will occur after the orientation meeting - once the loan has been funded, keys will be provided for move in.",
            number: '08', 
            active: '',
            image: '/images/site/our-process/header-bkg-8.jpg'
        }];

        console.log("test");

        $scope.swipeLeft = function(){
          console.log("swiped next");
          $('.carousel').carousel('next');
        }

        $scope.swipeRight = function(){
          console.log("swiped prev");
          $('.carousel').carousel('prev');
        }
       
    }])
      
}());