/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';

  angular.module('app.common')
  .filter('embedUrl', function() {
    return function(originalUrl) {
      // youtube
      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      const match = originalUrl.match(regExp);
      if (match&&match[7].length==11) {
        return 'https://www.youtube.com/embed/' + match[7];
      }

      // adds support for all variants of vimeo urls
      const parseUrl = originalUrl.split(
          /vimeo.com\/([a-z]+\/)*([0-9]{6,11})/i
      );

      return 'https://player.vimeo.com/video/' + Number(parseUrl[2]);
    }
  });
}());
