/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';

  angular.module('app.directives')
    .directive('unwPopup', ['$timeout', function($timeout) {
      return {
        templateUrl: '/common/unw-popup',
        restrict: 'A',
        scope: {},
        replace: true,
        transclude: true,

        link: function(scope, element) {
          var popup = angular.element(element[0]),
            parent = angular.element(element[0]).parent();

          parent.on('mouseenter', function() {
            popup.addClass('active');

            $timeout(function() {
              popup.addClass('fade-in');
            }, 150);
          });

          parent.on('mouseleave', function() {
            popup.removeClass('fade-in');

            $timeout(function() {
              popup.removeClass('active');
            }, 150);
          });
        }
      };

    }]);
}());
