/*jslint white: true, nomen: true */
/*global angular, $, document, window */
(function() {
  'use strict';

  angular.module('app.greenComponents')
    .directive('cardHover', function($window) {
        return function(scope, element, attribute) {
            var c = $(element).find('.card');
            var a = $(element).find('.plug');
            var position = c.position();
            var placement = attribute.placement;
            var margin = 10;
            var pin_h = 85;
            $(element).hover(function() {
                var c_h = c.height();
                var c_w = c.width();
                var c_x = 0;
                var c_y = 0;
                c.css('display', 'block');
                if(placement == 'top'){
                    c_y = '-' + ( c_h + a.height() - margin ) + 'px';
                    c.velocity({ opacity: 1, top: c_y }, 500, "easeOutQuad");
                }else if(placement == 'bottom'){
                    c_y = '-' + ( c_h + a.height() - margin ) + 'px';
                    c.velocity({ opacity: 1, bottom: c_y }, 500, "easeOutQuad");
                }else if(placement == 'right'){
                    c_x = ( c_w/2 ) - margin*4  + 'px';
                    c_y = '-' + (c_h/2 - (pin_h/2) + (margin * 2)) + 'px';
                    console.log(c_y);
                    c.css('top', c_y);
                    c.velocity({ opacity: 1, left: c_x }, 500, "easeOutQuad");
                }else if(placement == 'left'){
                    c_x = '-' + ( c_w + a.height() - margin) + 'px';
                    c_y = '-' + (c_h/2 - (pin_h/2) - margin) + 'px';
                    console.log(c_y);
                    c.css('top', c_y);
                    c.velocity({ opacity: 1, left: c_x }, 500, "easeOutQuad");
                }
            }, function() {
                $('.pin.top .card').css('display', 'none').css('top', '-200px').css('opacity', 0);
                $('.pin.right .card').css('display', 'none').css('left', '0px').css('opacity', 0); 
                $('.pin.left .card').css('display', 'none').css('left', '-300px').css('opacity', 0);  
                $('.pin.bottom .card').css('display', 'none').css('bottom', '-200px').css('opacity', 0);             
            });
        }
    })
}());