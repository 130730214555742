/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';  

  angular.module('app.homeownerTestimonials')                   
    .controller('HomeownerTestimonialsCtrl', ['$scope', 'TestimonialListDataService', function($scope, TestimonialListDataService) { 
 
     // Get all of the active communities
    TestimonialListDataService.getTestimonials().then(function(result) {  
      $scope.testimonials = result.data.testimonials;
      console.log(result.data.testimonials);        
    }); 

       
    }]);   
}());