/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeFloorplansCtrl', ['$scope', '$uibModal',
      function($scope, $uibModal) {

        $scope.collectionFilter = "";
        $scope.floorplans = [];
        $scope.floorplanRows = [];

        $scope.showFilters = {
          'Innovation': false,
          'Signature': false,
          'Distinction': false
        };

        var hideUnusedFilters = function(residences) {
          if (residences === undefined) {
            return;
          }

          angular.forEach(residences, function(c){
            var name = false;

            if (c.plan !== undefined && c.plan !== null) {
              if (c.plan.collection !== undefined && c.plan.collection !== null) {
                name = c.plan.collection.name;
              }
            }

            if (name) {
              $scope.showFilters[name] = true;
            }
          });
        };



        $scope.sortKey = 'price';
        $scope.sortRev = false;

        $scope.sort = function(key) {
          if (key === $scope.sortKey) {
            $scope.sortRev = !$scope.sortRev;
          } else {
            $scope.sortRev = true;
          }

          $scope.sortKey = key;
        };

        $scope.doSort = function(item) {
          if ($scope.sortKey === 'price') {
            return item[$scope.sortKey] > 0 ? item[$scope.sortKey] : ($scope.sortRev ? -1 : 10000000);
          }

          if ($scope.sortKey === 'plan.stories') {
            return item.plan.stories;
          }

          return item[$scope.sortKey];
        };

        $scope.communityPromise
          .then(function(community) {

            hideUnusedFilters(community.residences);

            angular.copy(community.residences, $scope.floorplans);
            // angular.copy($scope.floorplanRows = chunk(_.orderBy($scope.floorplans, ['sqft_low'], ['desc']), 4));
          })
        ;

        $scope.$watch('collectionFilter', function(){

          if ($scope.floorplans === undefined) {
            return;
          }

          var flops = $scope.floorplans.filter(filterFloorplansByCollection);

          $scope.floorplanRows = chunk(flops, 4);
        });


        var filterFloorplansByCollection = function(flp) {
          if ($scope.collectionFilter === '') {
            return flp;
          }

          if (flp.plan !== undefined && flp.plan) {
            if (flp.plan.collection !== undefined && flp.plan.collection) {
              if (flp.plan.collection.name === $scope.collectionFilter) {
                return flp;
              }
            }
          }
        };

        var chunk = function(arr, len) {
          var chunks = [];
          var i = 0;
          var n = arr.length;

          while (i < n) {
            chunks.push(arr.slice(i, i += len));
          }

          return chunks;
        };

        $scope.openResidence = function(residence) {
          window.location.href = '/'+residence.community.seo_name+'/'+residence.seo_name;
        };

        $scope.slidePrev = function(residence) {
          $('#carousel-residence-'+residence.id).carousel('prev');
        };

        $scope.slideNext = function(residence) {
          $('#carousel-residence-'+residence.id).carousel('next');
        };

        $scope.openFloorplanElevationsModal = function(floorplan) {
          $scope.selectedFloorplan = floorplan;
          $uibModal.open({
            templateUrl: 'floorplanElevationsModal.html',
            controller: 'floorplanDetailModalCtrl',
            windowClass: 'modal-auto-size floorplan-elevations-modal',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

      }
    ])
  ;
}());