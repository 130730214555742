'use strict';
/* global $, angular */

angular.module('app.common')
	.directive('carousel', ['$timeout',
	    function($timeout) {
	        return {
	            restrict: 'A',
	            link: function(scope, elem, attrs) {

				    $(document).ready(function(){

				        // Calling LayerSlider on the target element
				        $('#layerslider').layerSlider({
				 			twoWaySlideshow: true,
				 			thumbnailNavigation: 'disabled',
          					autoStart: false,
				 			navStartStop: false,
				 			navButtons: false,
				 			hoverPrevNext: false,
				 			navPrevNext: false,
				 			responsive: false,
				 			//responsiveUnder: 960,
				 			layersContainer : 1140,
				 			autoPlayVideos: true,
				 			skin: 'v5',
							skinsPath: '/vendor/layerslider/skins/',
							showCircleTimer: false,
							cbAnimStart: function(data) {
								console.log('function cbAnimStart() called, current layer is: ' + data.curLayerIndex + ', next layer is: ' + data.nextLayerIndex + '');
								if (data.nextLayerIndex === 1) {
									$('.seal').show();
								} else {
									$('.seal').hide();
								}
							},
				        });

				        $("a.carousel-control.left").on("click", function(e) {
				        	e.preventDefault();
				        	$('#layerslider').layerSlider('prev');
				        });

				        $("a.carousel-control.right").on("click", function(e) {
				        	e.preventDefault();
				        	$('#layerslider').layerSlider('next');
				        })

				    });
	        	}
	    	};
		}
	])
