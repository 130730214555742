/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular
    .module('app.excellenceInEverythingWeDo')
    .controller('ExcellenceInEverythingWeDoCtrl', ['$scope', '$uibModal',
      function($scope, $uibModal) {

        $scope.awards = [{
          id: 0,
          image: '/images/site/excellence-in-everything-we-do/nahb.png',
          desc: '<p class="text-center oswald name">2021 NAHB Best in American Living Award</p><p class="text-center blurb">Platinum - Healthy Home<br /><a href="/custom-floorplans/the-gladstone">The Gladstone</a></p>',
        }, {
          id: 1,
          image: '/images/site/excellence-in-everything-we-do/nahb-global-innovation.png',
          desc: '<p class="text-center oswald name">NAHB 2020 Global Innovation Award Finalist</p><p class="text-center blurb"><a href="/custom-floorplans/the-brazadian">The Brazadian</a></p>',
        }, {
          id: 2,
          image: '/images/site/excellence-in-everything-we-do/bia.png',
          desc: '<p class="text-center oswald name">2023 BIA Building Excellence Award</p><p class="text-center blurb">New Construction NGBS Certified<br /><a href="/custom-floorplans/the-mckenzie-modern">The McKenzie Modern</a></p><p class="text-center oswald name">2022 BIA Building Excellence Award</p><p class="text-center blurb">New Construction NGBS Certified<br /><a href="/custom-floorplans/kts-irvington">KT’s Irvington</a></p><p class="text-center oswald name">2021 BIA Building Excellence Award</p><p class="text-center blurb">New Construction NGBS Certified<br /><a href="/custom-floorplans/the-brazadian">The Brazadian</a></p><p class="text-center oswald name">2019 BIA Building Excellence Award</p><p class="text-center blurb">New Construction NGBS Certified<br />Ellis/Bush Residence</p><p class="text-center oswald name">BIA Builder of the Year 2013</p>',
        }, {
          id: 3,
          image: '/images/site/excellence-in-everything-we-do/the-columbian.png',
          desc: '<p class="text-center oswald name">The Columbian Best of Clark County 2023</p><p class="text-center blurb">Best Luxury and Community Home Builder - Finalist</p><p class="text-center oswald name">The Columbian Best of Clark County 2022</p><p class="text-center blurb">Best Luxury Home Builder - Winner</p>',
        }, {
          id: 4,
          image: '/images/site/excellence-in-everything-we-do/biaw.png',
          desc: '<p class="text-center oswald name">2013 BIAW Builder of the Year Nominee</p>',
        }, {
          id: 5,
          image: '/images/site/excellence-in-everything-we-do/nw-natural.png',
          desc: '<p class="text-center oswald name">2021 NW Natural New Homes Tour</p><p class="text-center blurb">Best Kitchen < $750,000<br /><a href="/custom-floorplans/the-gladstone">The Gladstone</a></p>',
        }, {
          id: 6,
          image: '/images/site/excellence-in-everything-we-do/sw-washington.png',
          desc: '<p class="text-center oswald name">2020 Vancouver Business Journal Top Project Awards</p><p class="text-center blurb">#1 in Residential Single Family<br /><a href="/custom-floorplans/the-brazadian">The Brazadian</a></p>',
        }, {
          id: 7,
          image: '/images/site/excellence-in-everything-we-do/ngbs.png',
          desc: '<p class="text-center oswald name">2017-2019, 2022 NGBS Partner of Excellence</p>',
        }];

        $scope.selectImage = function(gallery, index) {
          $scope.award = _.find($scope.awards, { 'id': index });
          $scope.galleryModal($scope.award);
        }

        $scope.galleryModal = function(award) {
          $scope.selectedGallery = $scope.gallery;
          $uibModal.open({
            templateUrl: 'awardModal.html',
            controller: 'galleryModalCtrl',
            windowClass: 'modal-auto-size award',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

      }
    ])
  ;
}());