/*jslint white: true, nomen: true */
/*global angular, $, document, window */
(function() {
  'use strict';

  angular.module('app.homeGallery')
    .directive('masonryGrid', ["$timeout", '$rootScope', function($timeout, $rootScope) {  
      return {
        restrict: 'A',
        link: function(scope, elem, attrs) { 
        		
            if(scope.$last) { 

                var $grid = $('.grid').imagesLoaded( function() {
                // init Masonry after all images have loaded
                $grid.masonry({
                    itemSelector: attrs.selector,
                    percentPosition: true,
                });
              });
             
            }

             scope.$watch(function(){
                if ($('.tab-pane').is(":visible")) {
                  // reload grid items on tab change
                 $(".grid").masonry('reloadItems');
                 // then relayout grid
                 var $grid = $('.grid').imagesLoaded( function() {
                    // init Masonry after all images have loaded
                    $grid.masonry({
                        itemSelector: attrs.selector,
                        percentPosition: true,
                    });
                  });
                
               }
              })

        }
      };
    }]);
}());