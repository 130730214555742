/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeGalleryCtrl', ['$scope', '$timeout', '$uibModal', 'DeviceService',
      function($scope, $timeout, $uibModal, DeviceService) {

      if (DeviceService.check() == 'phone') {
        $scope.galleryImagesLimitTo = 12;
        $scope.galleryVideosLimitTo = 12;
      }

      $scope.loadMoreImages = function() {
        $scope.galleryImagesLimitTo += 12;
      };

      $scope.loadMoreVideos = function() {
        $scope.galleryVideosLimitTo += 12;
      };



      $scope.selectImageAtIndex = function($index) {
        $scope.selectedIndex = $index;
      };

      $scope.selectImage = function(gallery, index) {
        $scope.selectImageAtIndex(index);
        $scope.galleryModal(gallery);
      }

      $scope.galleryModal = function(gallery) {
        $scope.selectedGallery = $scope.gallery;
        $uibModal.open({
          templateUrl: 'galleryModal.html',
          controller: 'galleryModalCtrl',
          windowClass: 'modal-auto-size gallery',
          animation: true,
          size: 'md',
          scope: $scope
        });
      };

      $scope.selectVideoAtIndex = function($index) {
        $scope.selectedIndex = $index;
      };

      $scope.selectVideo = function(gallery, index) {
        $scope.selectVideoAtIndex(index);
        $scope.videoModal(gallery);
      }

      $scope.videoModal = function(gallery) {
        $scope.selectedGallery = $scope.gallery;
        $uibModal.open({
          templateUrl: 'videoModal.html',
          controller: 'galleryModalCtrl',
          windowClass: 'modal-auto-size gallery',
          animation: true,
          size: 'md',
          scope: $scope
        });
      };


      $scope.selectedTag = "";

      $scope.slickConfig = {
        enabled: false,
        dots: false,
        autoplay: false,
        fade: true, 
        // speed: 1800,
        initialSlide: 0,
        infinite: true,
        autoplaySpeed: 10000,
        easing: "easeInElastic",
        useCSS: true,
        method: {},
        prevArrow: '<a class="left" href="#"><img src="/images/site/arrow-left.png" class="icon-prev"><div class="hover-ellipse"></div></a>',
        nextArrow : '<a class="right" href="#"><img src="/images/site/arrow-right.png" class="icon-next"><div class="hover-ellipse"></div></a>',
        event: {
          init: function(event, slick) {
            slick.slickGoTo(0); // slide to correct index when init
          },
          beforeChange: function(event, slick, currentSlide, nextSlide) {
            // return slideAnimation();
          }
        }
      };

      $scope.selectTag = function(tag) {
        $scope.slickConfig.enabled = false;
        $scope.selectedTag = tag;

        $timeout(function() {
          $scope.slickConfig.enabled = true;
        });
      };

      $scope.communityPromise
        .then(function(community) {
          $scope.selectTag('');
        })
      ;

      var matchTag = function(tag, tags) {
        // No selection means everything okay 
        if (!tag) {
          return true;
        }

        if (tags !== undefined) {
          for (var i = tags.length - 1; i >= 0; i--) {
            if (tags[i].name === tag) {
              return true;
            }
          }
        }

        return false;
      };

      $scope.galleryFilter = function(image) {
        return matchTag($scope.selectedTag, image.tags);
      };

      var slideAnimation = function() {

        return $('.transition').velocity({
          height: '165%'
        }, {
          duration: 700,
          complete: function() {
            $(this).velocity({
              opacity: 0
            }, {
              duration: 500,
              complete: function() {
                $('.item .carousel-caption').find('img').css('opacity', 0);
                $(this).css({
                  bottom: -50,
                  height: '0%'
                }).velocity({
                  bottom: 0,
                  opacity: 1
                }, {
                  duration: 300
                });
              }
            });
          }
        });
      };

    }]);
}());