/*jslint white: true, nomen: true */
/*global angular, $ */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('SubNavCtrl', ['$scope', '$location', '$rootScope', '$timeout', '$document',
      function($scope, $location, $rootScope, $timeout, $document) {

        $scope.toTheTop = function() {
          $document.scrollTopAnimated(0, 800).then(function() {
            //console && console.log('You just scrolled to the top!');
          });
        }

        //Watch affix event on main nav
        //var $nav = $('#header-wrapper');
        var $subNav = $('#sub-navigation-wrapper');
        //var $affix = $('.affix-static');
        var baseUrl = '';
        var offsetDistance = 100;
        var limit = 1;

        //url length check and format
        // if ($(".microsite-apartments-section, .microsite-home-section").length) {
        //   offsetDistance = 120;
        //   limit = 2;
        // }
        // if ($(".storage-microsite-section").length) {
        //   offsetDistance = 120;
        //   limit = 5;
        // }

        //scrollspy
        $(document).ready(function() {

          //trim the url based on these page classes
          var urlParts = $location.path().split('/');
          urlParts = urlParts.filter(function(item) {
            return item != ''
          });


          var num = 0;
          angular.forEach(urlParts, function(urlPart) {
            if (urlPart && num < limit) {
              baseUrl = baseUrl + urlPart + '/';
              num++;
            }
          });

          //on page load, trigger scroll to content
          var triggerScroll = function() { 
            if (urlParts[num] != undefined) {
              if (urlParts[num].length) {
                setTimeout(function() {
                  $('a[href="#' + urlParts[num] + '"]').click();
                }, 500);
              }
            }
          }

          if ($rootScope.loading === true) {
            $rootScope.$watch('loading', function(newValue) {
              if (newValue === false) {
                triggerScroll();
              }
            });
          } else {
            triggerScroll();
          }

        })

        $('body').scrollspy({
          target: '#sub-navigation-wrapper',
          offset: offsetDistance
        });

        // $subNav.on('click', function() {
        //   $scope.$apply(function() {
        //     var uri = $subNav.find('li.active a').attr('href');
        //     $location.path(baseUrl + uri.replace('#', ''), false);
        //     $location.replace();
        //   });
        //   //console.log("from sub nav controller");
        // })

        //on phone, hide menu if another one is about to be open
        // if (device.check() == 'phone' && $("#sub-navigation-wrapper").length) {
        //   $("#header-wrapper .navbar-toggle").on('click', function() {
        //     hideSubNav();
        //   })

        //   $(window).scroll(hideSubNav);
        // }

        //functions
        // function hideSubNav() {
        //   if ($("#sub-navigation-wrapper #sub-navigation").hasClass('in')) {
        //     $("#sub-navigation-wrapper #sub-navigation").removeClass('in')
        //     $(".navbar-toggle[data-target='#sub-navigation']").addClass('collapsed');
        //   }
        // }

        // function slideIn() {
        //   $subNav.addClass('fixed').hide()
        //   .velocity('stop')
        //   .velocity('transition.slideDownIn', {
        //     duration: 300,
        //     delay: 300},
        //     function() {
        //       $(this).addClass('slide-down');
        //   });
        // }

        // function slideOut() {
        //   $subNav.removeClass('slide-down')
        //   .velocity('stop')
        //   .velocity('transition.slideUpOut', 300, function() {
        //     $(this).removeClass('fixed')
        //     .velocity('transition.slideDownIn', 300, 'easeInQuad');
        //   });
        // }

      }
    ])
.value('duScrollDuration', 800);

}());