'use strict';
/* global $, angular */
angular.module("app.common").directive('treeParallax', [ function() {
  return function(scope, element, attrs) {
 //    var tween = TweenMax.to("#scrolling-trees", 1, {className: "+=scrolling"});

  // // build scene
  // var scene = new ScrollMagic.Scene({triggerElement: "#sub-nav", duration: 200, offset: -50})
  //        .setTween(tween)
  //        .addTo(controller);
 //  };

  var controller = new ScrollMagic.Controller();

        // create animation
        var tween = TweenMax.to('img.scrolling-trees', 2, {
          top: -300,
          zIndex: 1,
          //bottom: 0,
        //opacity: 0,
        });


        // Create the Scene and trigger when visible with ScrollMagic
        if($(window).height() > 1750) {
          var offsetValue = 650;
        }
        else if($(window).height() > 1220) {
          var offsetValue = 550;
        } else {
          var offsetValue = 350;
        }
        var scene = new ScrollMagic.Scene({
          triggerElement: 'span.nav-trigger',
          //triggerHook: 'onEnter',
          //duration: 550,
          duration: 200,
          offset: offsetValue, /* offset the trigger 150px below #scene's top */
        })
       
       
          .setTween(tween)
          .addTo(controller);
      };
}]);


