/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.moveInReady')
    .controller('MoveInReadyCtrl', ['$scope', 'CMSService', 'DataService', '$uibModal',
      function($scope, CMSService, DataService, $uibModal) {

        $scope.$watch(['homes'], function(is){
          if (is !== undefined && is.length > 0) {
            return;
          }

          var homesPromise       = DataService('/available-homes', 'homes').promise;

          homesPromise
            .then(function(homes){
              $scope.homes = homes;

              // for community filter dropdown
              $scope.communities = _.uniqBy(_.map(homes, 'community'), 'id');
            });
        });

        var communityFilter = false;
        var storiesFilter = false;
        $scope.communityFilterLabel = 'Choose a Community';
        $scope.storiesFilterLabel = 'Stories';

        $scope.filterCommunity = function(community) {
          if (!community) {
            communityFilter = false;
            $scope.communityFilterLabel = 'Any Community';
            return;
          }
          communityFilter = community.id;
          $scope.communityFilterLabel = community.name;
        };

        $scope.filterStories = function(stories) {
          if (!stories) {
            storiesFilter = false;
            $scope.storiesFilterLabel = 'Stories (Any)';
            return;
          }
          storiesFilter = stories;
          $scope.storiesFilterLabel = stories;
        };

        $scope.homeFilter = function(home) {
          // console.log(communityFilter, collectionFilter, home);

          if (communityFilter && home.community_id !== communityFilter) {
            return false;
          }

          if (storiesFilter) {
            if (home.residence && home.residence.plan) {
              if (home.residence.plan.stories !== storiesFilter) {
                return false;
              }
            } else if (home.plan) {
              if (home.plan.stories !== storiesFilter) {
                return false;
              }
            } else {
              return false;
            }

          }

          if ($scope.is_first_floor_master) {
            if (!home.residence.plan.is_first_floor_master) {
              return false;
            }
          }

          return home;
        };


        $scope.sortKey = 'price';
        $scope.sortRev = false;

        $scope.sort = function(key) {
          if (key === $scope.sortKey) {
            $scope.sortRev = !$scope.sortRev;
          } else {
            $scope.sortRev = true;
          }

          $scope.sortKey = key;
        };

        $scope.doSort = function(item) {
          var orderMonths = [
            'Now',
            'August',
            'September',
            'October',
            'November',
            'December',
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July'
          ];

          if (($scope.sortKey === 'availability' || $scope.sortKey === false) && item.available_label) {
            var month = item.available_label;
            return orderMonths.indexOf(month);
          }

          if ($scope.sortKey === 'price') {
            return item[$scope.sortKey] > 0 ? item[$scope.sortKey] : ($scope.sortRev ? -1 : 10000000);
          }

          return item[$scope.sortKey];
        };

        $scope.openHome = function(home) {
          window.location.href = '/available-homes/'+home.seo_name+'/'+home.id;
        };

        $scope.openHomeVideoModal = function(home) {
          $scope.selectedHome = home;
          $scope.homeVideo = $scope.selectedHome.video_url;
          $uibModal.open({
            templateUrl: 'homeVideoModal.html',
            controller: 'homeVideoModalCtrl',
            windowClass: 'video-modal',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

        $scope.openGetUpdatesModal = function() {
            var modalInstance = $uibModal.open({
              templateUrl: 'getNotifiedModal.html',
              controller: 'getNotifiedModalCtrl',
              windowClass: 'modal-auto-size',
              animation: true,
              size: 'md',
              scope: $scope
            });
        };

      }
    ])
  ;
}());