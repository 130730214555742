/*jslint white: true, nomen: true */
/*global angular, $, document, window */
(function() {
  'use strict';

  angular.module('app.greenComponents')
    .directive('introControl', function($window) {
        return function(scope, element, attribute) {
            var left = $(element).find('a.left');
            var right = $(element).find('a.right');
            left.on('click', function() {
              console.log('left');
              hideIntro('left');
            });
            right.on('click', function() {
              console.log('right');
              hideIntro('right');
            });

            function hideIntro(side){
              if(side == 'left'){
                  $('.item.house-right').removeClass('active');
                  $('.item.house-left').addClass('active');
                  $('.carousel-control.right').show();
                  $('.carousel-control.left').hide();
              }else{
                  $('.item.house-left').removeClass('active');
                  $('.item.house-right').addClass('active');
                  $('.carousel-control.right').hide();
                  $('.carousel-control.left').show();
              }
              
              $('.intro-wrap').velocity({opacity: 0},{
                complete: function(){
                    $(this).remove();
                }
              });
            }
        }
    })
}());