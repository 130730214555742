/*jslint white: true, nomen: true */
/*global angular, $, document, window */
(function() {
  'use strict';

  angular.module('app.microsite')
    .directive('scrollToNav', [function() { 
      return {
        restrict: 'A',
        link: function(scope, elem, attrs) {

          elem.find('a').on('click', function(event) {
            event.preventDefault();
            //console.log("from scroll to nav");

            elem.closest('.outer-wrap').find('.active').removeClass('active');
            elem.addClass('active');

            var target = $(this).attr('href');
            console.log(target);

            $('html, body').animate({
              scrollTop: $(target).offset().top - elem.data('offset')
            }, 1000);
          });

        }
      };
    }]);
}());