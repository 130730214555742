// Usage: toggle-filters target-object=".class"
(function() {

    'use strict';
    /* global angular */

    angular.module("app.common").directive('toggleFilters', ['device', function(device) {
        return function(scope, elem, attrs) {

            elem.bind('click', function() {
                $(attrs.targetObject).toggleClass('open');
                $(this).toggleClass('active');

                var isOpen = $(attrs.targetObject).is(':visible'),
                    slideDir = isOpen ? 'slideUp' : 'slideDown',
                    //btnText = isOpen ? ogText : 'Close',
                    dur = isOpen ? 200 : 400;
                $(attrs.targetObject).velocity(slideDir, {
                    easing: 'easeOutQuart',
                    duration: dur,
                    complete: function() {
                        // complete
                    }
                });

            });
        };
    }]);

})();
