/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentCommunitySelect', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-community-select',
        replace: true,
        scope: {
          defaultPlaceholder: '@',
          source: '=',
          model: '=',
          formObj: '=',
          formScope: '=',
          changeFunction: '=?',
          selectName: '=?'
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [function() {
          var self = this;

          if (! self.defaultPlaceholder) {
            self.defaultPlaceholder = 'SELECT YOUR URBAN NW COMMUNITY*';
          }
          if (typeof self.changeFunction === 'undefined') {
            self.changeFunction = function() {};
          }
          if (typeof self.selectName === 'undefined') {
            self.selectName = 'community_select';
          }
        }]
      };

    }]);
}());