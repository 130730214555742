(function() {

  'use strict';

  angular
    .module('app')
    .filter("currencyK", [
      function() {

        return function(number) {

          if (number === null) {
            return null;
          }

          if (number === 0) {
            return "No min";
          }

          var abs = Math.abs(number);
          var rounder = Math.pow(10, 0);
          var key = '';
          var power = {
            key: "K",
            value: 1000
          };

          var reduced = abs / power.value;

          reduced = Math.round(reduced * rounder) / rounder;

          if (reduced >= 1) {
            abs = reduced;
            key = power.key;
          }

          return '$' + abs + key;
        };
      }
    ])
  ;

})();
