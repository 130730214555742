/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentLandSelect', [function() {
      return {
        restrict: 'E',
        templateUrl: 'form-component-land-select',
        replace: true,
        scope: {
          defaultPlaceholder: '@',
          model: '=',
          formObj: '=',
          formScope: '=',
          selectName: '=?'
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: [function() {
          var self = this;

          if (! self.defaultPlaceholder) {
            self.defaultPlaceholder = 'CHOOSE ONE*';
          }
          if (typeof self.selectName === 'undefined') {
            self.selectName = 'land';
          }

          self.lands = [{
            "name": "I already have land",
            "value": "I already have land"
          }, {
            "name": "I still need land",
            "value": "I still need land"
          }];
        }]
      };

    }]);
}());