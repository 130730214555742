/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.microsite')
    .controller('MicrositeAmenitiesCtrl', ['$scope', '$q', '$timeout', 'uiGmapPromises', 'CommunitiesListDataService', 'LocalAmenitiesService', 'googleMapsStylesService',
      function($scope, $q, $timeout, uiGmapPromises, CommunitiesListDataService, LocalAmenitiesService, googleMapsStylesService) {
        var communityLocation = null,
            placesService = null;
        $scope.localAmenities = LocalAmenitiesService.data;
        $scope.selectedAmenity = $scope.localAmenities[0];

        $scope.map = {
            center: {
              latitude: 0,
              longitude: 0
            },
              control: {},
              markers: [],
              zoom: 13,
              options: {
                streetViewControl: false,
                draggable: true,
                scrollwheel: false,
                mapTypeControl: false,
                panControl: true,
                zoomControl: true,
                styles: googleMapsStylesService.unsaturatedBrown
              },
          };

        $scope.communityPromise

          .then(function(community) {

          uiGmapPromises
          .GoogleMapsApiReady()
          .then(function(GoogleMaps) {

            $scope.icon = {
              url: '/images/site/fa-map-marker.png',
              scaledSize: new GoogleMaps.Size(59, 65)
            };


            $scope.map.options.panControlOptions = {
              position: GoogleMaps.ControlPosition.RIGHT_CENTER
            };

            $scope.map.options.zoomControlOptions = {
              position: GoogleMaps.ControlPosition.RIGHT_CENTER
            };

              $scope.map.window = {
                marker: {},
                show: false,
                closeClick: function() {
                  this.show = false;
                  this.model = {};
                },
                options: {
                  maxWidth: 800,
                  pixelOffset: new GoogleMaps.Size(-9, -57)
                }
              };


              $scope.map.markersEvents = {
                click: function(marker, eventName, model, args) {

                  $scope.map.window.show = false;
                  $scope.map.window.marker = marker;
                  $scope.map.window.model = model;
                  $scope.map.window.show = true;

                  $scope.map.window.options = {
                    maxWidth: 800,
                    pixelOffset: new GoogleMaps.Size(-9, -57),
                    closeBoxURL: 'https://www.google.com/intl/en_us/mapfiles/close.gif'
                  };

                  var request = {
                    placeId: model.place_id
                  };

                  placesServicePromiseFactory(request, 'getDetails')
                  .then(function(details) {
                    model.address = details.formatted_address.replace(', United States', '');
                    model.phone = details.formatted_phone_number;

                    model.has_photo = false;
                  })
                  .then(uiGmapPromises.GoogleMapsIsAllReady)
                  .then(function(theMap) {
                    theMap.setCenter({
                      'lat': model.latitude,
                      'lng': model.longitude
                    });

                  });
                }
              };


            communityLocation = new GoogleMaps.LatLng($scope.community.latitude, $scope.community.longitude);

            uiGmapPromises.GoogleMapsIsAllReady()
            .then(function(theMap) {

              // resize trigger
              GoogleMaps.event.addDomListener(window, "resize", function() {
                theMap.setCenter({
                  'lat': $scope.map.center.latitude,
                  'lng': $scope.map.center.longitude
                });
              });


              placesService = new GoogleMaps.places.PlacesService($scope.map.control.getGMap());

              var placesSearches = $scope.localAmenities.map(searchForAmenity);

              // when I have all the promises
              $q.all(placesSearches)
              // after they all resolve, explicitly select "schools", the first amenity in the UI.
              .then(function() {
                $scope.selectAmenity($scope.localAmenities[0]);
              });
              return theMap;
            });
          });
        });


        $scope.selectAmenity = function(amenity) {

          if (amenity === undefined || !amenity) {
            return;
          }

          $scope.map.window.marker = {};
          $scope.map.window.show = false;

          $scope.selectedAmenity = amenity;
          $scope.map.markers = [];

          // Force this to happen in the next $digest
          $timeout(function() {
            $scope.map.markers = angular.copy(amenity.pins);
            fitBoundsWithCurrentMarkers();
            addCommunityMarkerToMap();
          }, 1);

        };

        var addMarkerToMap = function(amenity, searchResult) {

          angular.forEach(searchResult, function(place) {

            var amenity_index = $scope.localAmenities.indexOf(amenity);

            var marker = {
              id: amenity_index + place.place_id,
              place_id: place.place_id,
              name: place.name,
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
              pin: amenity.pin_icon,
              icon: amenity.icon,
              category: amenity_index,
              options: {
                visible: true
              }
            };

            // console.log(place.name, "is a", $scope.localAmenities[amenity_index].name);

            $scope.localAmenities[amenity_index].pins.push(marker);
          });

        };

        var fitBoundsWithCurrentMarkers = function() {

          uiGmapPromises
            .GoogleMapsApiReady()
            .then(function(GoogleMaps) {
              uiGmapPromises
                .GoogleMapsIsAllReady()
                .then(function(theMap) {

                  GoogleMaps.event.trigger(theMap, 'resize');

                  var newBounds = new GoogleMaps.LatLngBounds();

                  //newBounds.extend(new GoogleMaps.LatLng(austin.latitude, austin.longitude));

                  angular.forEach($scope.map.markers, function(marker) {
                    newBounds.extend(new GoogleMaps.LatLng(marker.latitude, marker.longitude));
                    newBounds.extend(new GoogleMaps.LatLng(0.005 + (1 * marker.latitude), 0.005 + (1 * marker.longitude)));
                  });

                  theMap.fitBounds(newBounds);

                  if (theMap.getZoom() > 13) {
                    theMap.setZoom(13);
                  }
                });
            });
        };


        var addCommunityMarkerToMap = function(){
            uiGmapPromises
              .GoogleMapsApiReady()
              .then(function(GoogleMaps) {

                var marker = {
                  id: 99999999,
                  place_id: 99999999,
                  name: $scope.community.name,
                  category: null,

                  latitude: parseFloat($scope.community.latitude),
                  longitude: parseFloat($scope.community.longitude),
                  icon: {
                    url: '/images/star.png',
                    scaledSize: new GoogleMaps.Size(30, 30)
                  },
                  options: {
                    visible: true
                  }
                };
                console.log(marker);

                $scope.map.markers.push(marker);
                console.log($scope.map.markers);

              })
            ;
        };



        // genereates a promise
        // which resolves the result of a Places search
        var placesServicePromiseFactory = function(request, service) {

          if (service === undefined) {
            service = 'search';
          }

          return $q(function(resolve, reject) {
            placesService[service](request, function(results, status) {
              if (status === 'OK') {
                resolve(results);
              } else {
                reject(status);
              }
            });
          });

        };

        // generates a promise
        // which resolves after adding a marker to the Amenity
        var searchForAmenity = function(amenity) {

          if (amenity === undefined || !amenity) {
            return;
          }

          var request = makeRequestObjectFromAmenity(amenity);

          return placesServicePromiseFactory(request)
            .then(function(results) {
              addMarkerToMap(amenity, results);
            })
            .catch(function(status) {
              // Remove the amenity from the list if we don't find any results
              $scope.localAmenities.splice($scope.localAmenities.indexOf(amenity), 1);
            });

        };

        // returns an object
        // for use as a Request to Places Search
        var makeRequestObjectFromAmenity = function(amenity) {
          return {
            location: communityLocation,
            radius: 4000,
            types: amenity.types
          };
        };
      
    }]);
}());