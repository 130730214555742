'use strict';
/* global $, angular */

angular.module("app.common").directive('preloader',['$timeout', function($timeout) {
	return {
        link: function(scope,element,attr){

            var spans = $('span[class^="message-"]').hide(),
                i = 0;

            (function cycle() {

                spans.eq(i).fadeIn(550)
                          .delay(1000)
                          .fadeOut(550, cycle);

                i = ++i % spans.length;

            })();

            $(window).on('load', function() {

            	$timeout(function() {
            		//$("svg.preloader animate").attr('values', '1;1;1');
	              $(".loader").fadeOut(700);
	            }, 1000);
                //$(".loader").fadeOut(700);
            });

        }
    }
}]);
