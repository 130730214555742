'use strict';
/* global $, angular */

angular.module("app.common").directive('dropdownButton', [function () {
    return {
        restrict: 'A',
        link: function (scope, elem, attrs) {
            elem.find(".dropdown-menu").on("click","li a",function(e){
            	e.preventDefault();
                elem.find("span.text").text($(this).text());
            })

            if(elem.hasClass("has-checkbox")) {
            	$('.has-checkbox .dropdown-menu').on({
				    "click":function(e){
				      e.stopPropagation();
				    }
				});
            }
        }
    };
}])