/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';  

  angular.module('app.reasonsToChooseUs')                   
    .controller('ReasonsToChooseUsCtrl', ['$scope', function($scope) { 

    	$scope.reasons = [{
            title: 'Quality Craftsmanship',
            text: 'Urban NW Homes include the latest technology and products installed by our skilled trade partners. Our construction process includes constant monitoring and testing of our homes to insure the highest level of quality in every home we build.',
            number: '01'
        }, {
            title: 'Options and Flexibility',
            text: 'We ensure that each home is a reflection of our client’s style and personal tastes. A large variety of options, custom allowances, and a hands on design approach ensure a fun and easy experience. Flexibility in building is something we take pride in, it’s part of creating a home with your unique signature.',
            number: '02'
        }, {
            title: 'Local, Sustainable Builders',
            text: 'Whenever possible, we use local products made within 500 miles of our site. We build homes that are designed for our city and our neighborhood.  And in 20, 30, 40 years, we want to see successful dynamic communities, with homes that have stood the test of time.',
            number: '03'
        }, {
            title: 'Efficient, Holistic Design',
            text: 'We make the most out of every square foot.  Wasted space is wasted material, and we want to ensure that each room, each cabinet, and every detail has a purpose. From foundations to flooring, our homes are designed with durability and sustainability in mind.  We’ve worked with our trade partners to build smarter, better homes.',
            number: '04'
        }, {
            title: 'Third Party Certification Process',
            text: 'Every home Urban NW builds is tested and Green Certified using the National Green Building Standard. This third party testing will only certify the highest quality homes. Other “green” programs are self-reporting - with no outside verification.',
            number: '05'
        }, {
            title: 'Built Right, The First Time',
            text: 'Durable construction practices, quality control, tried and true technology – all essential to delivering a quality home. We build our homes right the first time, and stand behind our construction.',
            number: '06'
        }];

        $scope.inView = function($index, $inview, $inviewpart, $event) {
            $($event.inViewTarget).addClass('in-view');
        }
       
    }]);   
}());