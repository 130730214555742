(function() {

  'use strict';
  /* global $, angular */

  angular.module("app.common").directive('mobileToggle',['device', function(device) {
    return function(scope, elem, attrs) {

		elem.on('click',function(e){

			var mobile = false;
			if(device.check() == 'phone'){
				mobile = true;
			}

			e.preventDefault();

			console.log(elem);

			//main-nav and sub nav
			if($(elem).hasClass('navbar-toggle') || $(elem).hasClass('regional-link') && mobile){  
          closeMenu('.sub-nav-fixed .navbar-toggle, .contact-us-link, .get-updates-link','#register-form');
			  	$(attrs.target).toggleClass('visible');
			}

			//favorites link
			if($(elem).hasClass('qsu-link') && mobile){  	

				$('#register-form').removeClass('sub');
				if($('.sub-nav-fixed').length){
					$('#register-form').addClass('sub');
				}

          closeMenu('.navbar-toggle, .contact-us-link, .get-updates-link',null);
			  	$(attrs.target).toggleClass('visible');
			}

			//contact link
			if(($(elem).hasClass('contact-us-link') || $(elem).hasClass('get-updates-link')) && mobile){
		  		closeMenu('.navbar-toggle','#register-form');
			}
			
			//prevent infinite loop
			$(elem).removeClass('triggered');
		})

	  	function closeMenu(elem,switchClass){
	      	$(elem).each(function(){  		
		      	if($(this).attr('aria-expanded') == "true" && !$(this).hasClass('triggered')){
		      		$(this).addClass('triggered').click();
		      	}
	      	})

	      	if(switchClass !== null){
		      	if($(switchClass).hasClass('visible')){
		      		$(switchClass).removeClass('visible');
		      	}
	      	}
	  	}

    };
  }]);

})();
