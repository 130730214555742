/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.moveInReadyDetail')
    .controller('MoveInReadyDetailCtrl', ['$scope', 'CMSService', 'CommunitiesListDataService', 'DataService', 'laravelParameters', '$timeout', '$uibModal', 'DeviceService', 'googleMapsStylesService', 'uiGmapPromises',
      function($scope, CMSService, CommunitiesListDataService, DataService, laravelParameters, $timeout, $uibModal, DeviceService, googleMapsStylesService, uiGmapPromises) {

        $scope.map = {
          control: {},
          markers: [],
          zoom: 10,
          center: {
            latitude: 45.679395,
            longitude: -122.597837
          },
          options: {
            streetViewControl: false,
            draggable: true,
            scrollwheel: false,
            mapTypeControl: false,
            styles: googleMapsStylesService.unsaturatedBrown
          },
          mapEvents: {
            click: function(maps, eventName, args) {
              hideWindows();
            }
          },
          window: {
            control: {},
            marker: {},
            show: false,
            closeClick: function() {
              this.show = false;
            },
            options: {
              boxClass: 'popup-wrapper',
              maxWidth: 800,
              //width: 550
            }
          }
        };

        var hideWindows = function() {
          // hide any windows
          $scope.map.window.show = false;
          $scope.map.window.marker = null;
          $scope.map.window.model = null;

          if (!$scope.$$phase) {
            $scope.$apply();
          }
        };

        var showWindow = function(GoogleMaps, marker, model) {
          $scope.map.window.options.pixelOffset = new GoogleMaps.Size(-183.75, -183.75);

          var markerCenter = {
            latitude: marker.getPosition().lat(),
            longitude: marker.getPosition().lng()
          };
          $scope.map.window.coords = markerCenter;

          hideWindows();

          $scope.map.window.marker = marker;
          $scope.map.window.model = model;
          $scope.map.window.show = true;

          uiGmapPromises.GoogleMapsIsAllReady()
            .then(function(theMap) {
            });
        };

   DataService('/' + laravelParameters.segment(0)[0]).promise.then(function(result) {
        $scope.home = result.home;
        $scope.otherPlans = result.other_plans;

        $scope.selectTag('');

        uiGmapPromises.GoogleMapsApiReady()
          .then(function init(GoogleMaps) {

            $scope.map.markers = [{
              longitude: $scope.home.longitude,
              latitude: $scope.home.latitude,
              idkey: $scope.home.id,
              icon: '/images/site/fa-map-marker.png',
              templateUrl: 'homeLocationWindow.html',
              home: $scope.home
            }];

            $scope.map.options = {
              panControl: true,
              panControlOptions: {
                position: GoogleMaps.ControlPosition.RIGHT_CENTER
              },
              zoomControl: true,
              zoomControlOptions: {
                position: GoogleMaps.ControlPosition.RIGHT_CENTER
              },
            }

            $scope.map.markersEvents = {
              click: function(marker, eventName, model, args) {
                showWindow(GoogleMaps, marker, model);
              }
            };
        });

        uiGmapPromises.GoogleMapsIsAllReady()
          .then(function(theMap) {
            $timeout(function() {
              if (theMap.getZoom() > 14) {
                theMap.setZoom(14);
              }
          }, 375);
        });
    });

        $scope.scrollToFormMoreAboutThisPlan = function(event) {
          $('.form-more-about-this-plan').velocity('scroll', {
            duration: 1500,
            offset: -75,
            delay: 0,
            easing: 'ease-in-out'
          });
        }

        // small hack:
        //    the affix position changes from relative (affix) to fixed (affix-top), causes the page offset change about 64px (height of #sub-nav-revision element)
        var affix_offset = (DeviceService.check() == 'phone') ? 58 : -64;

        $('#sub-nav-revision').affix({
          offset: {
            top: function () {
              return (this.top = $('header.menu').outerHeight(true) + $('.sub-header-revision').outerHeight(true) - 111)
            }
          }
        })
        .on('affix.bs.affix', function() {
          affix_offset = 0;
        })
        .on('affix-top.bs.affix', function() {
          affix_offset = (DeviceService.check() == 'phone') ? -58 : -64;
        });
        /*
        .on('affix-top.bs.affix', function() {
          $('header.menu.fixed').removeClass('push-it-up');
        });
        */
        var controller = new ScrollMagic.Controller();

        var scene = new ScrollMagic.Scene({
          triggerElement: '#sub-nav-revision',
          triggerHook: 'onEnter onLeave',
          offset: 345
        })
        .addTo(controller)
        .on('enter', function (e) {
          $('header.menu.fixed').addClass('push-it-up');
          console.log('trap');
        })
        .on('leave', function(e) {
          $('header.menu.fixed').removeClass('push-it-up');
        });

        $('#sub-navigation-wrapper').on('click', 'a', function(e) {
          // 141 = height of main header
          // 64 = height of sub nav
          var target = $(this).attr('href').substr(1),
              target_offset_top = $('#'+target).offset().top + affix_offset + 3;

          $('html, body').stop(true).animate({
            scrollTop: target_offset_top
          }, 800, 'easeOutQuart');
        });



        $scope.selectElevation = function(gallery, index) {
          $scope.selectImageAtIndex(index);
          $scope.elevationModal(gallery);
        }

        $scope.elevationModal = function(gallery) {
          $scope.selectedGallery = $scope.gallery;
          $uibModal.open({
            templateUrl: 'elevationModal.html',
            controller: 'galleryModalCtrl',
            windowClass: 'modal-auto-size gallery',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };



        var numOfTiles = (DeviceService.check() == 'phone') ? 12 : 18;
        $scope.galleryLimitTo = numOfTiles;
        //console.log($scope.floorplan.assets.length);
        $scope.loadMoreImages = function() {
          $scope.galleryLimitTo += numOfTiles;
        };



        $scope.subActive = 0;

   $scope.selectedTag = "";

        $scope.selectImageAtIndex = function($index) {
          $scope.selectedIndex = $index;
        };

        $scope.selectImage = function(gallery, index) {
          $scope.selectImageAtIndex(index);
          $scope.galleryModal(gallery);
        }

        $scope.galleryModal = function(gallery) {
          $scope.selectedGallery = $scope.gallery;
          $uibModal.open({
            templateUrl: 'galleryModal.html',
            controller: 'galleryModalCtrl',
            windowClass: 'modal-auto-size gallery',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

        $scope.slickConfig = {
          enabled: false,
          dots: false,
          autoplay: false,
          fade: true,
          // speed: 1800,
          initialSlide: 0,
          infinite: true,
          autoplaySpeed: 10000,
          easing: "easeInElastic",
          useCSS: true,
          method: {},
          prevArrow: '<a class="left" href="#"><img src="/images/site/arrow-left.png" class="icon-prev"><div class="hover-ellipse"></div></a>',
          nextArrow : '<a class="right" href="#"><img src="/images/site/arrow-right.png" class="icon-next"><div class="hover-ellipse"></div></a>',
          event: {
            init: function(event, slick) {
              slick.slickGoTo(0); // slide to correct index when init
            },
            beforeChange: function(event, slick, currentSlide, nextSlide) {
              // return slideAnimation();
            }
          }
        };

      $scope.$watch('home.assets', function(is){
      	if (is === undefined) {
      		return;
      	}

        $timeout(function() {
          $scope.slickConfig.enabled = true;
        }, 1000);
      })

      $scope.selectTag = function(tag) {
        $scope.slickConfig.enabled = false;

        $scope.selectedTag = tag;

        $timeout(function() {
          $scope.slickConfig.enabled = true;
        });
      };

      //$scope.selectTag('');

      var matchTag = function(tag, tags) {
        // No selection means everything okay 
        if (!tag) {
          return true;
        }

        if (tags !== undefined) {
          for (var i = tags.length - 1; i >= 0; i--) {
            if (tags[i].name === tag) {
              return true;
            }
          }
        }

        return false;
      };

      $scope.galleryFilter = function(image) {
        return matchTag($scope.selectedTag, image.tags);
      };

      function slideAnimation() {

        return $('.transition').velocity({
          height: '165%'
        }, {
          duration: 700,
          complete: function() {
            $(this).velocity({
              opacity: 0
            }, {
              duration: 500,
              complete: function() {
                $('.item .carousel-caption').find('img').css('opacity', 0);
                $(this).css({
                  bottom: -50,
                  height: '0%'
                }).velocity({
                  bottom: 0,
                  opacity: 1
                }, {
                  duration: 300
                });
              }
            });
          }
        });
      }

      // modal

      $scope.openGetUpdatesModal = function() {
  		  var modalInstance = $uibModal.open({
  		    templateUrl: 'moveInReadyGetUpdatesModal.html',
  		    controller: 'moveInReadyGetUpdatesModalCtrl',
  		    windowClass: 'modal-auto-size',
  		    animation: true,
  		    size: 'md',
  		    scope: $scope
  		  });
		};

      $scope.openCalendlyModal = function() {
        $scope.calendlyUrl = $scope.home.calendly_url;
        var modalInstance = $uibModal.open({
          templateUrl: 'calendlyModal.html',
          controller: 'calendlyModalCtrl',
          windowClass: 'modal-auto-size',
          animation: true,
          size: 'lg',
          scope: $scope
        });
    };

      
    }]);
}());