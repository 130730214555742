'use strict';
/* global $, angular */

angular.module("app.common").directive('getHeight', function() {
	return {
        link: function(scope,element,attr){ 
            var windowHeight = $(window).height();
            	subtract = parseInt(attr.subtract),
            	subtractHeight = parseInt(windowHeight) - subtract;
                if (attr.subtract == undefined) {
                    $(attr.targetObject).css('height', windowHeight);
                } else {
            	$(attr.targetObject).css('top', subtractHeight);
            }

            if (attr.affix !== undefined) {
                $(element).attr('data-offset-top', subtractHeight);
            }

        }
    }
});