/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular
    .module('app.customFloorplans')
    .controller('CustomFloorplansCtrl', ['$scope', 'CMSService', 'DataService', 'FilterService', '$uibModal', '$sce',
      function($scope, CMSService, DataService, FilterService, $uibModal, $sce) { 

        DataService('/api/plan', 'plans')
          .promise
          .then(function(plans) {
            $scope.floorplans = plans;
          })
        ;

        $scope.selectedTab = 'custom';

        $scope.switchTab = function(tab) {
          $scope.selectedTab = tab;
        }

        // range slider setup
        $scope.priceRange = {
          min: 0,
          max: 500
        }
      
        $scope.floorplanFilter = function(plan) {

          if ($scope.selectedTab === 'custom') {
            if (plan.collection.name != 'Custom')
              return false;
          }

          if ($scope.selectedTab === 'others') {
            if (plan.collection === undefined || !plan.collection) {
              return false;
            }

            if (plan.collection.name == 'Custom')
              return false;

            if (FilterService.collection)
              if (plan.collection.name != FilterService.collection)
                return false;
          }

          if (FilterService.stories)
            if (plan.stories != FilterService.stories)
              return false;

          if (FilterService.sqft_low && plan.sqft_low < FilterService.sqft_low)
              return false;

          if (FilterService.sqft_high) {
            var high = plan.sqft_high || plan.sqft_low;

            if (high > FilterService.sqft_high)
              return false;
          }

          if (FilterService.beds) {
            var low = plan.bed_low;
            var high = plan.bed_high || low;

            if (!(low <= FilterService.beds && FilterService.beds <= high))
              return false;
          }

          if (parseFloat(FilterService.baths)) {
            var low = parseFloat(plan.bath_low);
            var high = parseFloat(plan.bath_high) || low;

            if (!(low <= parseFloat(FilterService.baths) && parseFloat(FilterService.baths) <= high))
              return false;
          }

          if (FilterService.is_first_floor_master)
            if (plan.is_first_floor_master != FilterService.is_first_floor_master)
              return false;
          /*
          commented out to enable price

          if (FilterService.priceMin) {
            console.log(plan.price, FilterService.priceMin);
            if (plan.price < FilterService.priceMin) {
              return false;
            }
          }

          if (FilterService.priceMax) {
            var high = plan.price_high || plan.price;

            if (high > FilterService.priceMax) {
              return false;
            }
          }
          */
          return plan;

        };

        // $scope.videoUrl = function(floorplan) {
        //   return $sce.trustAsResourceUrl(floorplan.you_tube_url);
        // }

        $scope.openFloorplan = function(floorplan) {
          window.location.href = '/custom-floorplans/'+floorplan.seo_name;
        };

        $scope.slidePrev = function(floorplan) {
          $('#carousel-floorplan-'+floorplan.id).carousel('prev');
        };

        $scope.slideNext = function(floorplan) {
          $('#carousel-floorplan-'+floorplan.id).carousel('next');
        };

        $scope.openFloorplanElevationsModal = function(floorplan) {
          $scope.selectedFloorplan = floorplan;
          $uibModal.open({
            templateUrl: 'floorplanElevationsModal.html',
            controller: 'floorplanDetailModalCtrl',
            windowClass: 'modal-auto-size floorplan-elevations-modal',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

         $scope.openFloorplanVideoModal = function(floorplan) {
          $scope.selectedFloorplan = floorplan;
          $scope.customFloorplanVideo = $scope.selectedFloorplan.you_tube_url;
          $uibModal.open({
            templateUrl: 'floorplanVideoModal.html',
            controller: 'floorplanVideoModalCtrl',
            windowClass: 'video-modal', 
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

      }
    ])
  ;
}());