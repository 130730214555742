(function() {
  "use strict";

  angular
    .module('modules.googleMaps')
    .service('googleMapsStylesService', [
      function() {

        var data = {};

        data.unsaturatedBrown = [
          {
            "elementType": "geometry",
            "stylers": [
              {
                "hue": "#ff4400"
              },
              {
                "saturation": -68
              },
              {
                "lightness": -4
              },
              {
                "gamma": 0.72
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "labels.icon"
          },
          {
            "featureType": "landscape.man_made",
            "elementType": "geometry",
            "stylers": [
              {
                "hue": "#0077ff"
              },
              {
                "gamma": 3.1
              }
            ]
          },
          {
            "featureType": "water",
            "stylers": [
              {
                "hue": "#00ccff"
              },
              {
                "gamma": 0.44
              },
              {
                "saturation": -33
              }
            ]
          },
          {
            "featureType": "poi.park",
            "stylers": [
              {
                "hue": "#44ff00"
              },
              {
                "saturation": -23
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "hue": "#007fff"
              },
              {
                "gamma": 0.77
              },
              {
                "saturation": 65
              },
              {
                "lightness": 99
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "gamma": 0.11
              },
              {
                "weight": 5.6
              },
              {
                "saturation": 99
              },
              {
                "hue": "#0091ff"
              },
              {
                "lightness": -86
              }
            ]
          },
          {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
              {
                "lightness": -48
              },
              {
                "hue": "#ff5e00"
              },
              {
                "gamma": 1.2
              },
              {
                "saturation": -23
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "saturation": -64
              },
              {
                "hue": "#ff9100"
              },
              {
                "lightness": 16
              },
              {
                "gamma": 0.47
              },
              {
                "weight": 2.7
              }
            ]
          }
        ];

        data.amenitiesStyle = [

          {
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },

          {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#67a4ff"
              }
            ]
          },

          {
            "featureType": "landscape",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f5f5"
              },
              {
                "lightness": 20
              }
            ]
          },

          {
            "featureType": "landscape.natural",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#ffffff"
              },
              {
                "lightness": 17
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#ffffff"
              },
              {
                "lightness": 29
              },
              {
                "weight": 0.2
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              },
              {
                "lightness": 18
              }
            ]
          },
          {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              },
              {
                "lightness": 16
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#f5f5f5"
              },
              {
                "lightness": 21
              }
            ]
          },

          {
            "featureType": "administrative",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          },
          {
            "featureType": "administrative.neighborhood",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative.land_parcel",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "labels.text.stroke",
            "stylers": [
              {
                "visibility": "on"
              },
              {
                "color": "#ffffff"
              },
              {
                "lightness": 16
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "saturation": 36
              },
              {
                "color": "#333333"
              },
              {
                "lightness": 40
              }
            ]
          },
          {
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#fefefe"
              },
              {
                "lightness": 20
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "color": "#fefefe"
              },
              {
                "lightness": 20
              }
            ]
          },
          {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "color": "#fefefe"
              },
              {
                "lightness": 17
              },
              {
                "weight": 1.2
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
              {
                "color": "#ffffff"
              }
            ]
          }
        ];

        return data;
      }
    ])
  ;

})();