'use strict';
/* global $, angular */

angular.module("app.realtorDashboard").directive('activeLink', [function() {
	return function(scope, element, attrs) {
		if($("body").hasClass("realtor-dashboard")) {
			$("a.realtor-dashboard").addClass("active");
		}

		if($("body").hasClass("realtor-dashboard-client-list")) {
			$("a.client-list").addClass("active");
		}

		if($("body").hasClass("realtor-dashboard-add-client")) {
			$("a.add-client").addClass("active");
		}

		if($("body").hasClass("realtor-dashboard-edit-client")) {
      $("a.client-list").addClass("active");
    }
	};
}]);