'use strict';
/* global $, angular */

angular.module('app.common')
    .directive('toggleMenu', ['$timeout',
        function($timeout) {
            return {
                restrict: 'A',
                link: function(scope, elem, attrs) {

                  elem.on('click', function(e) {
                    $('.slideout-menu').toggleClass('open');
                  });

                }
            };
        }
    ])
