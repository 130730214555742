/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.collection')
    .controller('CollectionGalleryCtrl', ['$scope', '$timeout', '$window',
      function($scope, $timeout, $window) {

        $scope.selectedTag = "";

        $scope.slickConfig = {
          enabled: false,
          dots: false,
          autoplay: false,
          // speed: 1800,
          fade: true,
          initialSlide: 1,
          infinite: true,
          easing: "easeInElastic",
          method: {},
          prevArrow: '<a class="left" href="#"><img src="/images/site/arrow-left.png" class="icon-prev"><div class="hover-ellipse"></div></a>',
          nextArrow : '<a class="right" href="#"><img src="/images/site/arrow-right.png" class="icon-next"><div class="hover-ellipse"></div></a>',
          event: {
            init: function(event, slick) {
              slick.slickGoTo(0); // slide to correct index when init
            },
            beforeChange: function(event, slick, currentSlide, nextSlide) {
              // return slideAnimation();
            }
          }
        };

        var init = function(is) {
          if (is === undefined) {
            return;
          }

          $scope.collectionPromise
            .then(function(collection) {
              $timeout(function() {
                $scope.slickConfig.enabled = true;
              });
            })
          ;
        };

        $scope.$watch('collectionPromise', init);

        var slideAnimation = function() {

          return $('.transition').velocity({
            height: '165%'
          }, {
            duration: 700,
            complete: function() {
              $(this).velocity({
                opacity: 0
              }, {
                duration: 500,
                complete: function() {
                  $('.item .carousel-caption').find('img').css('opacity', 0);
                  $(this).css({
                    bottom: -50,
                    height: '0%'
                  }).velocity({
                    bottom: 0,
                    opacity: 1
                  }, {
                    duration: 300
                  });
                }
              });
            }
          });
        };

        angular.element($window).bind("scroll", function() {
          if (this.pageYOffset >= 2100) {
            if ($('.collection-widget').is(':visible')) $('.collection-widget').hide();
          } else {
            if ($('.collection-widget').is(':hidden')) $('.collection-widget').show();
          }
        });

      }
    ])
  ;
}());