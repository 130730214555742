/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.collection')
               
    .controller('CollectionsCtrl', ['$scope', 'DataService', '$timeout', 'laravelParameters',
      function($scope, DataService, $timeout, laravelParameters) {

        var uri = laravelParameters.segment(0)[0];

        var init = function() {
          if ($scope.collectionPromise !== undefined) {
            return;
          }

          $scope.collectionPromise = DataService('/' + uri, 'collection')
            .promise
            .then(function(collection) {
              $scope.collection = collection;
              return collection;
            });
        };

        $scope.$watch('collectionPromise', init);

      }
    ])
  ;
}());
