/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.realtorExtranet')
    .controller('RealtorExtranetCtrl', ['$scope', '$http', '$window', 'CommunitiesListDataService', '$uibModal',
      function($scope, $http, $window, CommunitiesListDataService, $uibModal) {

        var popover_content;

        angular.element('.btn-terms-and-conditions').popover({
          html: true,
          placement: 'top',
          trigger: 'focus',
          content: function() {
            if (! popover_content) {
              popover_content = angular.element('.popover-terms-and-conditions').html();
            }
            return popover_content;
          }
        }).data('bs.popover').tip().addClass('popover-terms-and-conditions');

        CommunitiesListDataService.getCommunities().then(function(result) {
          $scope.communities = result.communities;
        });

        $scope.formData = {};
        $scope.showLoader = false;
        $scope.showMessage = false;
        $scope.showForm = true;

        $scope.checkFormValidation = function(form) {
          $scope.loginTriedSubmit = true;
          if (!form.$valid) {
            console.log('form is not valid.');
            return;
          }
          processLoginForm();
        };

        function processLoginForm() {
          $scope.showLoader = true;
          $scope.showMessage = false;
          $scope.showForm = false;
          $http({
            method: 'POST',
            url: '/realtor-extranet',
            data: $.param($scope.formData), // pass in data as strings
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            } // set the headers so angular passing info as form data (not request payload)
          })
            .success(function(data) {
              if (data.status === 'success') {
                $scope.status = data.status;
                $scope.message = data.message;
                $scope.showLoader = false;
                $scope.showMessage = true;
                $scope.showForm = false;
                $window.location.href = '/realtor-extranet/my-profile';
              } else {
                $scope.status = data.status;
                $scope.message = data.message;
                $scope.showLoader = false;
                $scope.showMessage = true;
                $scope.showForm = true;
              }
            });
        }

        $scope.openForgotPasswordModal = function() {
          $uibModal.open({
            templateUrl: 'realtorExtranetForgotPasswordModal.html',
            controller: 'realtorExtranetForgotPasswordModalCtrl',
            windowClass: 'modal-auto-size',
            animation: true,
            size: 'md',
            scope: $scope
          });
        };

      }]);
}());