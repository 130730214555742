/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.realtorDashboard')
    .controller('RealtorExtranetAddClientCtrl', ['$scope', '$http', '$window', '$filter', 'CommunitiesListDataService', '$uibModal', function($scope, $http, $window, $filter, CommunitiesListDataService, $uibModal) { 
    	
    	CommunitiesListDataService.getCommunities().then(function(result) {
	      $scope.communities = result.communities; 
	      console.log(result);
	    });

      $http({
        method: 'GET',
        url: '/realtor-extranet/my-profile',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        } // set the headers so angular passing info as form data (not request payload)
      })
      .success(function(data) {
        if (data.status == 'success') {
          $scope.realtor = angular.copy(data.realtor);
          $scope.realtor.created_at = $filter('date')(Date.parse($scope.realtor.created_at), 'MMMM d, yyyy');
        } else {
        }
      });

      // helpful link: http://www.programming-free.com/2014/05/angularjs-retrieve-multiple-checkbox-selected-options.html
      $scope.selectedCommunities = [];

      // toggle community selections
      $scope.toggleCommunitySelection = function toggleSelection(id) {
        var idx = $scope.selectedCommunities.indexOf(id);
  
        // is currently selected
        if (idx > -1) {
          $scope.selectedCommunities.splice(idx, 1);
        }
  
        // is newly selected
        else {
          $scope.selectedCommunities.push(id);
        }
      };

      $scope.showLoader = false;
      $scope.showMessage = false;
      $scope.showForm = true;

      $scope.checkFormValidation = function(form) {
        $scope.registerTriedSubmit = true;
        if (!form.$valid) {
          console.log('form is not valid.');
          return;
        }
        processRegisterForm();
      };

      function processRegisterForm() {
        $scope.showLoader = true;
        $scope.showMessage = false;
        $scope.showForm = false;

        $scope.formData.origin = $window.location.href;
        $scope.formData.communities = $scope.selectedCommunities;

        $http({
          method: 'POST',
          url: '/realtor-extranet/add-client',
          data: $.param($scope.formData), // pass in data as strings
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } // set the headers so angular passing info as form data (not request payload)
        })
        .success(function(data) {
          if (data.status == 'success') {
            $scope.status = data.status;
            $scope.message = data.message;
            $scope.showLoader = false;
            $scope.showMessage = true;
            $scope.showForm = false;
            $window.location.href = '/realtor-extranet/add-client';
          } else {
            $scope.status = data.status;
            $scope.message = data.message;
            $scope.showLoader = false;
            $scope.showMessage = true;
            $scope.showForm = true;
          }
        });
      }

      var modalInstance;

      $scope.openTermsAndConditionsModal = function() {
        modalInstance = $uibModal.open({
          templateUrl: 'realtorExtranetTermsAndConditionsModal.html',
          controller: 'realtorExtranetTermsAndConditionsModalCtrl',
          windowClass: 'modal-auto-size modal-realtor-extranet-terms-and-conditions',
          animation: true,
          size: 'md',
          scope: $scope
        });
      };
      
    }]);
}());