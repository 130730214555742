'use strict';
/* global $, angular */

angular.module('app.microsite')
.directive('stickyNav', [function () {
  return {
    restrict: 'A',
    link: function (scope, elem, attr) {

	    // add padding or margin when element is affixed
		$(elem).on("affix.bs.affix", function() {
		  $("#welcome").addClass("padded");
		  $("header.fixed, .mobile-wrap.fixed").addClass('push-it-up');
		});

		// remove it when unaffixed
		$(elem).on("affix-top.bs.affix", function() {
		 $("#welcome").removeClass("padded");
		 $("header.fixed, .mobile-wrap.fixed").removeClass('push-it-up');
		});
    }
  };
}]);