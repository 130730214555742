(function() {
  "use strict";

  angular
    .module("modules.trustAsResourceUrl")
    .filter('trustAsResourceUrl', ['$sce', function($sce) {
      return function(val) {
        return $sce.trustAsResourceUrl(val);
      };
    }])
  ;

})();
