/*jslint white: true, nomen: true */
/*global $, angular */
/*global communitiesListDataServiceWash, floorplansListDataServiceWash */
/*global residencesListDataServiceWash */
(function() {
  'use strict';

  angular.module('app.common')

  .service('CommunitiesListDataService', ['$http',function($http) {
    var communitiesListDataServiceResultFailMessageText = 'No communities found',
      data = {
        communitiesPromise: null,
        communityPromise: null,
        floorplansPromise: null,
        floorplanPromise: null,
        residencesPromise: null,
        residencePromise: null
      },

      getCommunities = function() {
        if (data.communitiesPromise === null) {
          data.communitiesPromise = $http.get('/api/community', {
            headers: {
              'Cache-Control': 'no-cache'
            },
            cache: true
          }).then(communitiesListDataServiceWash);
        }

        return data.communitiesPromise;
      },

      getCommunity = function(communityId) {
        if (data.communityPromise === null) {
          data.communityPromise = $http.get('/api/community/'+communityId, {
            cache: true
          }).then(communitiesListDataServiceWash);
        }

        return data.communityPromise;
      },

      communitiesListDataServiceWash = function(result) {
        var communitiesAssets = [],
          successResult = {},
          failResult = {
            status: 'failure',
            communities: [],
            communitiesAssets: [],
            errors: communitiesListDataServiceResultFailMessageText
          },
          formatCommunity = function(community) {
            community.longitude = parseFloat(community.longitude);
            community.latitude = parseFloat(community.latitude);
            angular.forEach(community.assets, function(asset) {
              communitiesAssets.push(asset);
            });

            return community;
          };

        if (result === undefined || result.data === undefined || result.data.status === undefined) {
          return failResult;
        }

        if (result.data.status === 'failure') {
          return failResult;
        }

        if (result.data.communities) {
          angular.forEach(result.data.communities, function(community) {
            formatCommunity(community);
          });

          successResult.communities = result.data.communities;
          successResult.communitiesAssets = communitiesAssets;
        } else if (result.data.community) {
          successResult.community = formatCommunity(result.data.community);
        }

        successResult.status = result.data.status;
        successResult.errors = 'none';

        return successResult;
      },

      getFloorplans = function() {
        if (data.floorplansPromise === null) {
          data.floorplansPromise = $http.get('/api/plan', {
            headers: {
              'Cache-Control': 'no-cache'
            },
            cache: true
          }).then(floorplansListDataServiceWash);
        }

        return data.floorplansPromise;
      },

      getFloorplan = function(planId) {
        if (data.floorplansPromise === null) {
          data.floorplansPromise = $http.get('/api/plan/'+planId, {
            headers: {
              'Cache-Control': 'no-cache'
            },
            cache: true
          }).then(floorplansListDataServiceWash);
        }

        return data.floorplansPromise;
      },

      floorplansListDataServiceWash = function(result) {
        var successResult = {},
          failResult = {
            status: 'failure',
            floorplans: [],
            errors: communitiesListDataServiceResultFailMessageText
          };

        if (result === undefined || result.data === undefined || result.data.status === undefined) {
          return failResult;
        }

        if (result.data.status === 'failure') {
          return failResult;
        }

        successResult = {
          status: result.data.status,
          errors: 'none'
        };

        if (result.data.plans) {
          successResult.floorplans = result.data.plans;
        } else if (result.data.plan) {
          successResult.floorplan = result.data.plan;
        }

        return successResult;
      },

      getResidences = function() {
        if (data.residencesPromise === null) {
          data.residencesPromise = $http.get('/api/residence', {
            headers: {
              'Cache-Control': 'no-cache'
            },
            cache: true
          }).then(residencesListDataServiceWash);
        }

        return data.residencesPromise;
      },

      getResidence = function(residenceId) {
        if (data.residencePromise === null) {
          data.residencePromise = $http.get('/api/residence/'+residenceId, {
            cache: true
          }).then(residencesListDataServiceWash);
        }

        return data.residencePromise;
      },

      residencesListDataServiceWash = function(result) {
        var successResult = {},
          failResult = {
            status: 'failure',
            residences: [],
            errors: communitiesListDataServiceResultFailMessageText
          };

        if (result === undefined || result.data === undefined || result.data.status === undefined) {
          return failResult;
        }

        if (result.data.status === 'failure') {
          return failResult;
        }

        successResult = {
          status: result.data.status,
          errors: 'none'
        };

        if (result.data.residences) {
          successResult.residences = result.data.residences;
        } else if (result.data.residence) {
          successResult.residence = result.data.residence;
        }

        return successResult;
      };

    return {
      getCommunities: getCommunities,
      getCommunity: getCommunity,
      getFloorplans: getFloorplans,
      getFloorplan: getFloorplan,
      getResidences: getResidences,
      getResidence: getResidence
    };
  }]);
}());
