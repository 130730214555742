(function() {

  'use strict';
  /* global angular */

  angular.module("app.common").directive('autoHeightMobile', ['device', function(device) {
    return function(scope, elem, attrs) {

      $(window).bind('load resize', function(){
        var mobile = false;
        if(device.check() == 'phone' || device.check() == 'ipad'){
            mobile = true;
        }
        if($(window).height() > attrs.maxHeight){
          if(mobile){
            elem.height(attrs.maxHeight);
          }
        }else{
          if(mobile){
            elem.height($(window).height() * attrs.minHeight);
          }  
        }
      })

    };
  }]);

})();
