'use strict';
/* global $, angular */

angular.module('app.findYourHome')
.directive('activeButton', [function () {
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      elem.on('click',function(){
        if(!elem.hasClass('active')){
          elem.closest(attrs.parent).find('.active').removeClass('active');
        }

        if (!$('.find-your-home-map').hasClass('visible')) {
          $('.community-panel').removeClass('visible');
        } 

         if (!$('.community-panel').hasClass('visible')) {
          $('.find-your-home-map').removeClass('visible');
        } 
        elem.toggleClass('active');
        $(attrs.target).toggleClass('visible');

      });  
    }
  };
}]);