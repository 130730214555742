(function() {

  'use strict';

  angular.module('app')

    .controller('WhereWeBuildMapCtrl', ['$scope', 'uiGmapPromises', 'DataService', '$timeout', 'googleMapsStylesService',
      function($scope, uiGmapPromises, DataService, $timeout, googleMapsStylesService) {


        var hideWindows = function() {
          // hide any windows
          $scope.map.window.show = false;
          $scope.map.window.marker = null;
          $scope.map.window.model = null;

          if (!$scope.$$phase) {
            $scope.$apply();
          }
        };


        var fitBoundsWithCurrentMarkers = function(theMap, GoogleMaps) {
          GoogleMaps.event.trigger(theMap, 'resize');

          var newBounds = new GoogleMaps.LatLngBounds();

          angular.forEach($scope.map.markers, function(marker) {
            newBounds.extend(new GoogleMaps.LatLng(marker.latitude, marker.longitude));
          });

          theMap.fitBounds(newBounds);
        };


        var bindCommunitiesToMarkers = function(communities) {
          var markers = [];

          angular.forEach(communities, function(community) {

            var marker = {
              id: community.id,
              place_id: community.id,
              name: community.name,
              latitude: community.latitude,
              longitude: community.longitude,
              community: community,
              icon: $scope.icon,
              options: {
                boxClass: 'unw-popup',
                visible: true
              }
            };

            markers.push(marker);

          });

          return markers;
        };

        var zoomMax = 15;
        var zoomMin = 2;

        $scope.map = {
          control: {},
          markers: [],
          zoom: 10,
          center: {
            latitude: 45.679395,
            longitude: -122.597837
          },
          options: {
            streetViewControl: false,
            draggable: true,
            scrollwheel: false,
            mapTypeControl: false,
            panControl: true,
            zoomControl: true,
            maxZoom: zoomMax,
            minZoom: zoomMin,
            styles: googleMapsStylesService.unsaturatedBrown
          },
          mapEvents: {
            click: function(maps, eventName, args) {
              hideWindows();
            }
          },
          window: {
            control: {},
            marker: {},
            show: false,
            closeClick: function() {
              this.show = false;
            },
            options: {
              boxClass: 'popup-wrapper',
              closeBoxURL: 'https://www.google.com/intl/en_us/mapfiles/close.gif',
              maxWidth: 800,
              width: 550
            }
          }
        };

        uiGmapPromises.GoogleMapsApiReady()
          .then(function(GoogleMaps) {

            $scope.icon = {
              url: '/images/site/fa-map-marker.png',
              scaledSize: new GoogleMaps.Size(59, 65)
            };


            $scope.map.options.panControlOptions = {
              position: GoogleMaps.ControlPosition.RIGHT_CENTER
            };

            $scope.map.options.zoomControlOptions = {
              position: GoogleMaps.ControlPosition.RIGHT_CENTER
            };


            $scope.map.markersEvents = {
              mouseover: function(marker, eventName, model, args) {
                $timeout(function() {
                  $scope.map.window.options.pixelOffset = new GoogleMaps.Size(-285, -237);

                  $scope.map.window.control.hideWindow();
                  $scope.map.window.coords = marker.center;
                  $scope.map.window.show = false;
                  $scope.map.model = {};

                  $scope.map.window.marker = marker;
                  $scope.map.window.model = model;
                  $scope.map.window.show = true;
                });
              }
            };


            DataService('/api/community', 'communities')
              .promise
              .then(function(communities) {
                $scope.communities = communities;
                return communities;
              })
              .then(bindCommunitiesToMarkers)
              .then(function(markers) {
                angular.copy(markers, $scope.map.markers);
              })

              .then(function(){

                uiGmapPromises.GoogleMapsIsAllReady()
                  .then(function(theMap) {


                    $timeout(function(){

                      // re-size and re-center map
                      //$scope.map.window.options.pixelOffset = new GoogleMaps.Size(-6, -53);
                      fitBoundsWithCurrentMarkers(theMap, GoogleMaps);

                      // resize trigger
                      GoogleMaps.event.addDomListener(window, "resize", function() {
                        fitBoundsWithCurrentMarkers(theMap, GoogleMaps);
                      });
                    });

                  })
                ;

              })
            ;



          })
        ;


      }
    ])
  ;

}());