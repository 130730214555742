(function() {
  'use strict';

 /* <div slick-carousel slick-carousel-as-nav-for="#thumbs" */

  angular.module('app.floorplans')
    .directive('slickCarousel', ['$timeout', function($timeout) {
      return {
        restrict: 'A',
        scope: {
          slickCarouselAsNavFor: '@',
          slickCarouselInit: '='
        },
        link: function(scope, elem, attrs) {

            var gallery = $(attrs.main);
            var thumbnail = $(attrs.thumb);

            var slick_config = {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: false,
                  dots: true,
                  fade: true,
                  speed: 1200,
                  cssEase: 'linear'
                };

            if (scope.slickCarouselAsNavFor) {
              slick_config.asNavFor = scope.slickCarouselAsNavFor;
            }

            if (scope.slickCarouselInit) {
              console.log("Init");
              $('#thumbnail-carousel').slick( slick_config );


              $('#thumbnail-carousel').find('.slick-track').height('670px');

              // On before slide change
              $('#thumbnail-carousel').on('beforeChange', function(event, slick, currentSlide, nextSlide){
                slideAnimation();
                console.log("test");
              });
            

            var destroy = function () {
              console.log("You sunk my battleship");
              var slickness = angular.element($('#thumbnail-carousel'));

              if (slickness.hasClass('slick-initialized')) {
                slickness.remove('slick-list');
                slickness.slick('unslick');
              }

              $('#thumbnail-carousel').off('beforeChange');

              return slickness;
            };
            scope.$on('destroy', destroy);

            var slideAnimation = function() {

                $('.transition').velocity({height:'165%'},{duration:700,complete:function(){
                    $(this).velocity({opacity:0},{duration:500,complete:function(){
                        $('.item .carousel-caption').find('img').css('opacity',0);
                        $(this).css({
                            bottom: -50,
                            height: '0%'
                        }).velocity({
                            bottom: 0,
                            opacity: 1
                        },{duration:300,complete:function(){
                            //fadeInCaption();
                        }});
                    }});
                }});
            }
          }

        }
      };

    }]);
}());