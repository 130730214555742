/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.warrantyRequest')
    .controller('WarrantyRequestCtrl', ['$scope', 'CMSService', 'CommunitiesListDataService', function($scope, CMSService, CommunitiesListDataService) {
    	
    	CMSService.loadContents('warranty_request_pdfs').then(function(result) {
	      $scope.pdfs = result.data.contents[0].content_fields;
	    });

	    $scope.ctas = [
		    {
		    	caption: "Urban NW Limited One-Year Warranty",
		    	fieldName: "one_year_warranty",
		    	className: "one-year-warranty"
		    },
		    {
		    	caption: "Prevent Mold in Moist, Damp Environments",
		    	fieldName: "damp_environments",
		    	className: "mold"
		    },
		    /*
		    {
		    	caption: "How To Seal Your Crawlspace",
		    	fieldName: "crawl_space",
		    	className: "crawlspace"
		    },
		    */
		    {
		    	caption: '"Drain the Pain Away" Drain Checklist',
		    	fieldName: "drain_check_list",
		    	className: "drain"
		    }
	    ];


    }]);
}());