(function() {
  'use strict';

  angular.module('app.moveInReady')
    .controller('getNotifiedModalCtrl', ['$scope', '$uibModalInstance',
      function($scope, $uibModalInstance) {

        $scope.close = function() {
          $uibModalInstance.close();
        };
      }]);
})();