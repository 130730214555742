(function() {
  'use strict';
  /* global $, angular */
  // Tests for this service are not written yet
  // Handle form functions and submissions and return message and instructions
  // Service only displays "pretty" messages, not debug info from the API
  angular.module("app.forms")
    .service("FormService", ['$http', 'TestDataService',
      function($http, TestDataService) {
        var formService = {};

        /*========================================
        =            Helper FUnctions            =
        ========================================*/

        /**
         *
         * Allows you to toggle the inclusion of an item
         *
         */
        formService.toggleSelection = function(id, selectedItems) {
          if (!(selectedItems instanceof Array)) {
            selectedItems = [];
          }

          // id = parseInt(id);
          if (!id) {
            return selectedItems;
          }

          var idx = selectedItems.indexOf(id);
          // is already  selected, remove it
          if (idx > -1) {
            selectedItems.splice(idx, 1);
          }
          // is newly selected, add it
          else {
            selectedItems.push(id);
          }

          return selectedItems;
        };

        /**
         *
         * Allows you to open / close form sections
         *
         */
        formService.toggleClass = function(event) {
          $(event.currentTarget).closest('.checkbox-wrapper').siblings().find('.in').removeClass('in');
          $(event.currentTarget).toggleClass('in');
        };

        /**
         *
         * Checks if a date element is older than a given age
         *
         */
        formService.requirePrevious = function(formDateElement) {
          var cutoff = new Date();
          cutoff.setYear(cutoff.getFullYear() - 3);

          var current = new Date(formDateElement);
          current.setDate(current.getDate() + 1);

          return current >= cutoff ? true : false;
        };

        /**
         *
         * Maintains page height after large forms are submitted and hidden
         *
         */

        formService.pageResizeOnSubmit = function() {
          var wh = $(window).height();
          var h = $('.header').height() + $('footer').height();
          /**

            TODO:
            - Make this work for any form that needs it
            - Second todo item

           */

          var $p = $('.full-interest-list');
          $p.height(wh - h);
        };

        /*=====  End of Helper FUnctions  ======*/


        /*=========================================
        =            Prefill Functions            =
        =========================================*/

        /**
         *
         * Populates the formData object in a form's Angular controller
         *
         */
        formService.prefillForm = function(prefillMode, formName) {

          var prefillContent = {
            formData: {
              origin: window.location.href,
              type: formName
            },
            formResult: {
              showForm: true,
              showLoader: false,
              showMessage: false
            }
          };

          // Get test data if called for, and mix it with regular formData
          if (prefillMode) {
            prefillContent.formData = this.mix(
              TestDataService.get(formName),
              prefillContent.formData
            );
          }

          switch (formName) {
            case 'warrantyRequest':
              prefillContent.formData.warranty_issues = [];
              prefillContent.formData.warranty_issues.push({
                  location: null,
                  description: null
                });
              break;

            default:
              break;
          }
          return prefillContent;
        };

        /**
         *
         * Combines two objects together
         *
         */
        formService.mix = function(source, target) {
          for (var key in source) {
            if (source.hasOwnProperty(key)) {
              target[key] = source[key];
            }
          }
          return target;
        };


        /*=====  End of Prefill Functions  ======*/


        /*=====================================================
        =            Form submission and results processing   =
        =====================================================*/

        /**
         *
         * Hanldes the submit action and returns prepared results to Angular
         *
         */
        formService.submit = function(url, formData) {
          return $http({
            method: 'POST',
            url: url,
            data: $.param(formData),
            paramSerializer: '$httpParamSerializerJQLike',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            } // set the headers so angular passing info as form data (not request payload)
          // }).then(this.formServiceSubmitResultWash(result));
          }).then(formService.formServiceSubmitResultWash);
        };

        /**
         *
         * Returns the defulat fail text
         *
         */
        formService.getSubmitResultFailMessageText = function() {
          return 'Something went wrong, please contact us.';
        };

        /**
         *
         * Prepares the results from backend form processor
         *
         */
        formService.formServiceSubmitResultWash = function(result) {
          var failResult = {
            status: 'failure',
            showForm: true,
            showLoader: false,
            showMessage: true,
            message: formService.getSubmitResultFailMessageText()
          };

          if (result === undefined || result.data === undefined || result.data.status === undefined) {
            return failResult;
          }

          if (result.data.status === 'failure') {
            return failResult;
          }

          var successResult = {
            status: 'success',
            showForm: false,
            showLoader: false,
            showMessage: true,
            message: result.data.message
          };

          return successResult;
        };

        /**
         *
         * Hanldes the realtor sign up submit action
         * and returns prepared results to Angular
         *
         */
        formService.submitRealtorSignUp = function(url, formData) {
          return $http({
            method: 'POST',
            url: url,
            data: $.param(formData),
            paramSerializer: '$httpParamSerializerJQLike',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            } // set the headers so angular passing info as form data (not request payload)
          // }).then(this.formServiceSubmitResultWash(result));
          }).then(formService.formServiceSubmitRealtorSignUpResultWash);
        };

        /**
         *
         * Prepares the realtor sign up results from backend form processor
         *
         */
        formService.formServiceSubmitRealtorSignUpResultWash = function(result) {
          var failResult = {
            status: 'failure',
            showForm: true,
            showLoader: false,
            showMessage: true,
            message: formService.getSubmitResultFailMessageText(),
            redirect: false
          };

          if (result === undefined || result.data === undefined || result.data.status === undefined) {
            return failResult;
          }

          if (result.data.status === 'failure') {
            if (result.data.message) {
              failResult.message = result.data.message;
            }
            return failResult;
          }

          var successResult = {
            status: 'success',
            showForm: false,
            showLoader: false,
            showMessage: true,
            message: result.data.message,
            redirect: '/realtor-extranet/my-profile'
          };

          return successResult;
        };

        /*=====  End of Form submission and results processing   ======*/

        return formService;
      }]);
})();