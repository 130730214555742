/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.homecareTips')
    .controller('HomeCareTipsCtrl', ['$scope', 'CMSService', function($scope, CMSService) { 
    	
    	CMSService.loadContents('tips').then(function(result) {
	      $scope.tips = result.data.contents;
	    });
      
    }]);
}());