(function() {
  'use strict';
  angular.module('app.forms')
    .service('TestDataService', [function() {
      var testDataService = {};

      testDataService.get = function(formName) {
        return this[formName]();
      };

      testDataService.warrantyRequest = function() {
        return {
          first_name: "John",
          last_name: "Doe",
          email: "john@doe.com",
          phone: "1234567890",
          address_1: "123 Main Street",
          city: "Anytown",
          state: "CA",
          zip: "12345"
        };
      };

      return testDataService;
    }]);
})();