(function() {
  'use strict';

  angular.module('app.common')
    .controller('galleryModalCtrl', ['$scope', '$uibModalInstance',
      function($scope, $uibModalInstance) {
        $scope.close = function() {
          $uibModalInstance.close();
        };
      }]);
})();