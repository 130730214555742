/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .controller('FullInterestListCtrl', ['$scope', '$rootScope', 'FormService', 'CommunitiesListDataService', 'TrackingService',
      function($scope, $rootScope, FormService, CommunitiesListDataService, TrackingService) {

        /*====================================
        =            Declarations            =
        ====================================*/

        var self = this,
          prefillMode = false;

        self.formData = FormService.prefillForm(prefillMode, 'fullInterestList').formData;
        self.formResult = FormService.prefillForm(prefillMode, 'fullInterestList').formResult;

        CommunitiesListDataService.getCommunities().then(function(result) {
          self.communities = result.communities;
        });

        if (typeof self.trackingName === 'undefined') {
          self.trackingName = 'fullInterestListForm';
        }

        self.checkFormValidation = function(formsArray) {
          self.triedSubmit = true;

          for (var i = 0; i <= formsArray.length - 1; i++) {
            var form = formsArray[i];

            // Trigger submit manually
            form.$setSubmitted();
            $rootScope.$broadcast('multiFormSubmit');

            if (! form.$valid) {
              return;
            }
          }
          processFullInterestListForms();
        };

        function processFullInterestListForms() {
          self.formResult.showForm = false;
          self.formResult.showLoader = true;

          FormService.submit('/forms/full-signup', self.formData)
            .then(function(result) {
              self.formResult = result;
              if (result.status === 'success') {
                console.log('tracking: ', 'fullInterestListForm');
                TrackingService.logTrackingClick('fullInterestListForm', result);
              }
            });
        }

      }]);
}());