'use strict';
/* global $, angular */

angular.module('app.common')
    .directive('slideout', ['$timeout',
        function($timeout) {
            return {
                restrict: 'A',
                link: function(scope, elem, attrs) {

                    $(document).ready(function() {
                    	var panel = attrs.panel;
                    	var menu = attrs.menu;
                    	var side = attrs.side;
                    	var button = attrs.button;
                        var slideout = new Slideout({
                            'panel': document.getElementById(panel),
                            'menu': document.getElementById(menu),
                            'padding': 256,
                            'tolerance': 70,
                            'side': side,
                            'touch': false
                        });

                        // On slideout open/close
                        slideout.on('open', function() {
                            console.log('Open');
                            $(button).addClass('is-active');
                        });
                        slideout.on('close', function() {
                            console.log('Close');
                            $(button).removeClass('is-active');
                        });
                        // Toggle slideout button
                        $(button).on('click', function() {
                            $(this).toggleClass('is-active');
                            slideout.toggle();
                        });
                    });

                }
            };
        }
    ])
