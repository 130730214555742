/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';  

  angular.module('app.whyBuildGreen')                   
    .controller('WhyBuildGreenCtrl', ['$scope', function($scope) { 

       
    }]);   
}());