/*jslint white: true, nomen: true */
/*global angular, $, document, window */
(function() {
  'use strict';

  angular.module('app.floorplans')
    .directive('thumbCarousel', ['$timeout', function($timeout) { 
      return {
        restrict: 'A',
        link: function(scope, elem, attrs) {

            var gallery = $(attrs.main);
            var thumbnail = $(attrs.thumb);

            var destroy = function () {
              var slickness = angular.element($('#thumbs'));
              if (slickness.hasClass('slick-initialized')) {
                slickness.remove('slick-list');
                slickness.slick('unslick');
              }

              return slickness;
            };
            scope.$on('destroy', destroy);

            if(scope.$last) {

                $('#thumbs').slick({
                  asNavFor: '#thumbnail-carousel',
                  slidesToShow: 6,
                  slidesToScroll: 1,
                  dots: false,
                  centerMode: false,
                  focusOnSelect: true,
                  prevArrow: '<div class="slick-prev"><i class="fa fa-angle-left" aria-hidden="true"></i></div>', 
                  nextArrow: '<div class="slick-next"><i class="fa fa-angle-right" aria-hidden="true"></i></div>',
                });

            }

        }
      };
    }]);
}());