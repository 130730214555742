'use strict';
/* global $, angular */

angular.module("app.common").directive('checkbox', [function() {
	return function(scope, element, attrs) {
		if(scope.$last) {
			$('input.checkbox').on("click", function(event) {
			    $(event.currentTarget).closest(attrs.targetObject).toggleClass("selected");
			});
		}
	};
}]);