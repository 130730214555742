'use strict';
/* global $, angular */

angular.module("app.common").directive('fixedNav', [ function() {
  return function(scope, element, attrs) {
    // sticky header nav on scroll
    $(function () {
      if(!$('body').hasClass('no-animation')) {
        var headerController = new ScrollMagic.Controller();

          // Create the Scene and trigger when visible with ScrollMagic
        var headerScene = new ScrollMagic.Scene({
          triggerHook: "onLeave",
          triggerElement: 'span.nav-trigger', 
          //duration: 0,
          //offset: 0, 
        })
        //.setPin("#navbar-home")
        .setClassToggle("header.menu, header.mobile-header", "fixed")
        .addTo(headerController);
    }
    });
  };
}]);