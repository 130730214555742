/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';

  angular.module('app.forms')
    .directive('formComponentDatepicker', [function() {
      return {
        restrict: "E",
        templateUrl: 'form-component-datepicker',
        scope: {
          formattedDate: "=ngModel",
          opened: "=",
          defaultPlaceholder: "=",
          formObj: "=",
          formScope: "=",
          datepickerName: "=",
          isRequired: "=?",
          futureOnly: "=?",
          pastOnly: "=?",
          pastAndFuture: "=?",
          mode: "=?"
        },
        bindToController: true,
        controllerAs: 'vm',
        link: function() {},
        controller: ['$scope', '$filter', function($scope, $filter) {

          var self = this,
              minDate,
              maxDate;

          self.dateOptions = {
            showWeeks: false
          };

          // Set required to true by default, but allow for conditional or false
          if (typeof self.isRequired === 'undefined') {
            self.isRequired = true;
          }

          if (typeof self.mode !== 'undefined') {
            self.dateOptions.datepickerMode = self.mode;
          }

          // Format the date selection and make it available
          $scope.$watch(function () {
                 return self.dt;
             }, function(value){
                  self.formattedDate = $filter('date')(value, 'yyyy-MM-dd');
             });

          if (typeof self.pastOnly === 'undefined') {
            self.pastOnly = false;
          }

          if (typeof self.pastAndFuture === 'undefined') {
            self.pastAndFuture = false;
          }

          if (typeof self.futureOnly === 'undefined' && ! self.pastOnly && ! self.pastAndFuture) {
            self.futureOnly = true;
          }

          if (typeof self.testing === 'undefined') {
            self.testing = false;
          }

          if (self.futureOnly) {
            // Enabled future only selections
            minDate = new Date();
            minDate.setDate(minDate.getDate() + 1);

            maxDate = new Date();
            maxDate.setYear(maxDate.getFullYear() + 3);
            maxDate.setDate(maxDate.getDate() + 1);

            self.dateOptions.minDate = minDate;
            self.dateOptions.maxDate = maxDate;
          }

          if (self.pastOnly) {
            // Enabled past only selections
            maxDate = new Date();
            maxDate.setDate(maxDate.getDate() + 1);

            self.dateOptions.maxDate = maxDate;
          }

          self.open = function(event) {
            event.preventDefault();
            event.stopPropagation();
            self.opened = true;
          };

          self.clear = function() {
            self.ngModel = null;
          };

        }]
      };

    }]);
}());
